import classNames from 'classnames'
import { useEffect } from 'react'
import successIcon from 'src/assets/images/icons/success.svg'
import infoIcon from 'src/assets/images/icons/info.svg'
import errorIcon from 'src/assets/images/icons/error.svg'
import styles from './Badge.module.scss'

const icons = {
	success: successIcon,
	info: infoIcon,
	error: errorIcon
}
const Badge: React.FC<{
	show: boolean
	toggleShow: () => void
	color?: 'success' | 'info' | 'error'
	text?: string
	time?: number
}> = ({ show, toggleShow, color = 'success', text, time = 7000 }) => {
	useEffect(() => {
		if (show) {
			setTimeout(() => {
				toggleShow()
			}, time)
		}
		return () => {
			clearTimeout()
		}
	}, [show])
	const getClassName = classNames(styles.added, [styles[color]])
	if (!text) {
		return null
	}
	return (
		<div className={getClassName}>
			<img src={icons[color]} className={styles.added__icon} alt={text} />
			<p className={styles.added__text}>{text}</p>
		</div>
	)
}

export default Badge
