import { gql } from '@apollo/client'
import FRAGMENT_USER from '../fragments'

export const CREATE_USER = gql`
	mutation createUser(
		$address: String!
		$city: String!
		$codes: [String]!
		$countryId: ID!
		$email: String!
		$firstName: String!
		$lastName: String!
		$password1: String!
		$password2: String!
		$phoneNumber: String!
		$postCode: String!
		$termsOfUse: Boolean!
	) {
		createUser(
			address: $address
			city: $city
			codes: $codes
			countryId: $countryId
			email: $email
			firstName: $firstName
			lastName: $lastName
			password1: $password1
			password2: $password2
			phoneNumber: $phoneNumber
			postCode: $postCode
			termsOfUse: $termsOfUse
		) {
			user {
				...User
			}
			ok
			errors {
				fieldName
				errors
			}
			licences {
				activationDate
				status
				isValid
				code
				profileCode
			}
		}
	}
	${FRAGMENT_USER}
`
export const UPDATE_USER = gql`
	mutation updateUser(
		$address: String!
		$city: String!
		$countryId: ID!
		$firstName: String!
		$lastName: String!
		$phoneNumber: String!
		$postCode: String!
	) {
		updateUser(
			address: $address
			city: $city
			countryId: $countryId
			firstName: $firstName
			lastName: $lastName
			phoneNumber: $phoneNumber
			postCode: $postCode
		) {
			user {
				...User
			}
			ok
			errors {
				fieldName
				errors
			}
		}
	}
	${FRAGMENT_USER}
`

export const LOGIN_USER = gql`
	mutation loginUser($email: String!, $password: String!) {
		loginUser(email: $email, password: $password) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`

export const CREATE_CHILD = gql`
	mutation createChild($children: [ChildInput]) {
		createChild(children: $children) {
			ok
			agreement
			errors {
				childErrors {
					fieldName
					errors
				}
			}
		}
	}
`

export const LOGOUT_USER = gql`
	mutation logoutUser {
		logoutUser {
			ok
		}
	}
`

export const UPDATE_PASSWORD = gql`
	mutation updateUserPassword($oldPassword: String!, $password1: String!, $password2: String!) {
		updateUserPassword(
			oldPassword: $oldPassword
			password1: $password1
			password2: $password2
		) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const RESET_USER_PASSWORD = gql`
	mutation resetUserPassword($email: String!) {
		resetUserPassword(email: $email) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CONFIRM_RESET_USER_PASSWORD = gql`
	mutation confirmResetUserPassword(
		$password1: String!
		$password2: String!
		$token: String!
		$uidb64: String!
	) {
		confirmResetUserPassword(
			password1: $password1
			password2: $password2
			token: $token
			uidb64: $uidb64
		) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`

export const UPDATE_USER_EMAIL = gql`
	mutation updateUserEmail($email: String!, $password: String!) {
		updateUserEmail(email: $email, password: $password) {
			user {
				email
			}
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CONFIRM_USER_EMAIL = gql`
	mutation confirmUserEmail($token: String!, $uidb64: String!) {
		confirmUserEmail(token: $token, uidb64: $uidb64) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`

export const UPDATE_CHILD = gql`
	mutation updateChild($childId: ID, $name: String, $policyId: ID, $agreed: Boolean) {
		updateChild(childId: $childId, name: $name, policyId: $policyId, agreed: $agreed) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const CREATE_ANONYMOUS_USER = gql`
	mutation createAnonymousUser($codes: [String], $extraLicenceQty: Int) {
		createAnonymousUser(codes: $codes, extraLicenceQty: $extraLicenceQty) {
			ok
			errors {
				fieldName
				errors
			}
			licenceIsValid {
				isValid
				code
				error
			}
		}
	}
`

export const CREATE_PAYMENT = gql`
	mutation createPayment {
		createPayment {
			paymentUrl
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const ADD_LICENCES = gql`
	mutation add_lic($codes: [String]!) {
		addLicence(codes: $codes) {
			licenceIsValid {
				isValid
				code
				error
			}
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const ADD_EXTRA_LICENCES = gql`
	mutation add_extra_lic($codes: [String], $extraLicenceQty: Int!) {
		addExtraLicence(codes: $codes, extraLicenceQty: $extraLicenceQty) {
			user {
				licences {
					totalAmount
					codes
				}
			}
			licenceIsValid {
				isValid
				code
				error
			}
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const DELETE_ANONYMOUS_USER = gql`
	mutation deleteAnonymousUser {
		deleteAnonymousUser {
			ok
		}
	}
`

export const DELETE_EXTRA_LICENCES = gql`
	mutation deleteNewExtraLicences {
		deleteNewExtraLicences {
			ok
		}
	}
`

export const EXTEND_REC_TIME = gql`
	mutation setRecTime($childId: ID!, $end: String!, $start: String!) {
		setRecreationTime(childId: $childId, end: $end, start: $start) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const RESET_EXTEND_TIME = gql`
	mutation resetRecreationTime($childId: ID!) {
		resetRecreationTime(childId: $childId) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
