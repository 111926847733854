import classNames from 'classnames'
import { useSteps } from 'src/context/steps-context'
import styles from './Progress.module.scss'

type TProgress = React.FC<{ step: number; className?: string; position?: 'top' | 'bottom' }>

const Progress: TProgress = ({ step, className, position = 'bottom' }) => {
	const { state } = useSteps()

	const lastStep = state.steps || 3

	const steps = 100 / lastStep
	const progressClass = classNames(styles.progress, className)
	const lineClass = classNames(styles.progress__line, styles[position])
	return (
		<div className={progressClass}>
			Step • <span className={styles.progress__active}>{step}</span> / {lastStep}
			<span className={lineClass} style={{ width: `${step * steps}%` }} />
		</div>
	)
}

export default Progress
