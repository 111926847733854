import classNames from 'classnames'
import React from 'react'
import Loader from '../Loader'
import styles from './Button.module.scss'

type ButtonProps = {
	children: any
	onClick?: () => void
	color?: 'primary' | 'secondary' | 'outlined' | 'empty' | 'danger'
	block?: boolean
	classname?: string
	isDisable?: boolean
	isLoading?: boolean
	type?: 'button' | 'submit' | 'reset'
}

const Button: React.FC<ButtonProps> = ({
	children,
	onClick,
	color,
	block,
	classname,
	isLoading,
	isDisable = false,
	type = 'button'
}) => {
	const getClass = classNames(styles.btn, classname, styles[color], {
		[styles._block]: block
	})
	return (
		<button onClick={onClick} className={getClass} type={type} disabled={isDisable}>
			{isLoading ? <Loader width="20px" center /> : children}
		</button>
	)
}

export default Button
