import { useNavigate } from 'react-router'
import { useToggle } from 'react-use'
import { useDispatchUser, useUser } from 'src/context/auth-context'
import { useRef } from 'react'
import useOnClickOutside from 'src/hooks/useOnClickOutside'
import classNames from 'classnames'
import { LOGOUT_USER } from 'src/graphql/mutation'
import { useMutation } from '@apollo/client'
import ROUTES_NAME from 'src/router/routesNames'
import styles from './ProfileButton.module.scss'

const MenuButton: React.FC<{ onClick: () => void; icon: string }> = ({
	onClick,
	children,
	icon
}) => {
	return (
		<button className={styles.drop__btn} onClick={onClick}>
			<i className={icon} /> {children}
		</button>
	)
}

const ProfileButton = () => {
	const [open, toggle] = useToggle(false)
	const navigate = useNavigate()
	const { dispatch } = useDispatchUser()
	const { state } = useUser()
	const ref = useRef(null)
	useOnClickOutside(ref, () => toggle(false))
	const [logoutUser] = useMutation(LOGOUT_USER, {
		onCompleted: (data) => {
			if (data.logoutUser.ok) {
				dispatch({
					type: 'LOGOUT'
				})
				navigate(ROUTES_NAME.HOME)
			}
		}
	})

	const onSetting = () => {
		navigate(ROUTES_NAME.SETTINGS)
	}
	const onDashboard = () => {
		navigate(ROUTES_NAME.DASHBOARD)
	}
	const onLogout = async () => {
		await logoutUser()
	}
	const getButtonClass = classNames(styles.profile, { [styles.active]: open })
	return (
		<div className={styles.profile__wrap} ref={ref}>
			<button className={getButtonClass} onClick={toggle}>
				Profile
			</button>
			{open && (
				<div className={styles.drop}>
					<div className={styles.drop__top}>
						<div className={styles.drop__name}>
							{state.user.firstName} {state.user.lastName}
						</div>
						<span className={styles.drop__mail}>{state.user.email}</span>
					</div>
					<MenuButton onClick={onDashboard} icon="icon-dashboard">
						Dashboard
					</MenuButton>
					<MenuButton onClick={onSetting} icon="icon-settings">
						Settings
					</MenuButton>
					<MenuButton onClick={onLogout} icon="icon-log-out">
						Log out
					</MenuButton>
				</div>
			)}
		</div>
	)
}

export default ProfileButton
