import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router'
import DashboardChild from 'src/components/elements/DashboardChild'
import Loader from 'src/components/elements/Loader'
import { GET_POLICIES } from 'src/graphql/query'
import { TChild } from 'src/graphql/types'
import ROUTES_NAME from 'src/router/routesNames'
import Button from 'src/components/elements/Button'
import { FC } from 'react'

type DashboardLicencesProps = {
	childrenArr: TChild['child'][]
	loading: boolean
	refetch: any
}
const DashboardLicences: FC<DashboardLicencesProps> = ({ childrenArr, loading, refetch }) => {
	const { data: policies } = useQuery(GET_POLICIES)

	const navigate = useNavigate()
	const onAddLicense = () => {
		navigate(ROUTES_NAME.DASHBOARD_ADD_LICENCE)
	}
	return (
		<div>
			<div className="dashboard__btnwrapper">
				<Button color="primary" classname="dashboard__plusbtn" onClick={onAddLicense}>
					<i className="icon-plus" /> Add licence
				</Button>
			</div>
			{loading ? (
				<Loader width="80px" color="grey" center />
			) : (
				<div className="dashboard__children">
					{childrenArr.map((child: TChild['child']) => (
						<DashboardChild
							refetch={refetch}
							key={child.licence.code}
							child={child}
							policies={policies?.policies}
						/>
					))}
				</div>
			)}
		</div>
	)
}

export default DashboardLicences
