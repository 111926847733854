import classNames from 'classnames'
import styles from './AddChildFooter.module.scss'

const colors = {
	IN_PROGRESS: 'orange',
	PROTECTED: 'green'
}
const text = {
	IN_PROGRESS: {
		title: 'Access has not yet been set up',
		subtitle: 'Status will be updated when you complete the steps above'
	},
	PROTECTED: {
		title: 'Access Confirmed',
		subtitle:
			'When you start using the app it will send confirmation to this dashboard that you are activiated. This may take a little while.'
	}
}

const AddChildFooter: React.FC<{ status: string }> = ({ status }) => {
	const iconClass = classNames(styles.footer__color, styles[colors[status]])
	return (
		<div className={styles.footer}>
			<div className={styles.footer__wrap}>
				<div className={iconClass} />
				<div className={styles.footer__text}>
					<div className={styles.footer__title}>{text[status].title}</div>
					<div className={styles.footer__subtitle}>{text[status].subtitle}</div>
				</div>
			</div>
		</div>
	)
}

export default AddChildFooter
