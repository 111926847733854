import { useMedia } from 'react-use'
import Button from 'src/components/elements/Button'
import DesktopMenu from 'src/components/elements/DesktopMenu'
import styles from '../../Header.module.scss'

const LoginMenu: React.FC<{ onLogin: () => void; toggleMenu: () => void }> = ({
	onLogin,
	toggleMenu
}) => {
	const isWide = useMedia('(min-width: 767px)')
	return (
		<div className={styles.header__right}>
			<Button color="outlined" onClick={onLogin}>
				Login
			</Button>
			{isWide ? (
				<DesktopMenu />
			) : (
				<button className={styles.header__menu} onClick={toggleMenu}>
					<span />
					<span />
				</button>
			)}
		</div>
	)
}

export default LoginMenu
