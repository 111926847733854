import classNames from 'classnames'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useToggle } from 'react-use'
import { InputProps } from './types'

const ConnectForm: React.FC = ({ children }) => {
	const methods = useFormContext()

	// @ts-ignore
	return children({ ...methods })
}

const FieldInput: React.FC<InputProps> = (props) => {
	const {
		label,
		type = 'text',
		name,
		inputNativeProps,
		errors,
		className,
		connectForm,
		required,
		isPassword,
		inputClassName,
		onBlur,
		...otherProps
	} = props
	const [show, setShow] = useToggle(false)

	const classInput = classNames('formControl__input', inputClassName, {
		formControl__errorBorder: errors
	})
	const passwordIcon = !show ? (
		<span className="icon-big-eye-on" />
	) : (
		<span className="icon-big-eye-off" />
	)
	const getPasswordType = !show ? 'password' : 'text'

	const innerProps = {
		type: isPassword ? getPasswordType : type,
		className: classInput,
		id: name,
		name,
		...otherProps
	}
	return (
		<div className={classNames('formControl', className)}>
			{label && (
				<label htmlFor={name} className="formControl__label">
					{label}
				</label>
			)}
			<div className="formControl__wrap">
				{connectForm ? (
					<ConnectForm>
						{({ register }) => (
							<input
								{...innerProps}
								{...inputNativeProps}
								{...register(name)}
								onBlur={onBlur}
								required={required}
							/>
						)}
					</ConnectForm>
				) : (
					<input
						{...innerProps}
						{...inputNativeProps}
						onBlur={onBlur}
						required={required}
					/>
				)}

				{isPassword && (
					<button type="button" className="formControl__btn-show" onClick={setShow}>
						{passwordIcon}
					</button>
				)}
			</div>
			{errors && <span className="formControl__error">{errors.message}</span>}
		</div>
	)
}

export default FieldInput
