import styles from '../DashboardChild.module.scss'

const ChildInfo = ({ licence }) => {
	return (
		<div className={styles.info}>
			<div className={styles.info__expDate}>
				<div className={styles.info__title}>Expiration date</div>
				<div className={styles.info__subtitle}>{licence.expirationDate || 'N/A'}</div>
			</div>
			<div className={styles.info__school}>
				<div className={styles.info__title}>School</div>
				<div className={styles.info__subtitle}>{licence.school?.name || 'N/A'}</div>
			</div>
		</div>
	)
}

export default ChildInfo
