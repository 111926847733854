import { useLazyQuery } from '@apollo/client'
import classNames from 'classnames'
import { useEffect } from 'react'
import ChildStatus from 'src/components/elements/ChildStatus'
import { GET_CHILD } from 'src/graphql/query'
import { TChild, TPolicy } from 'src/graphql/types'
import useAccordion from 'src/hooks/useAccordion'
import { transformPolicies } from 'src/utils'
import styles from './DashboardChild.module.scss'
import {
	ChildChillTime,
	ChildDevices,
	ChildInfo,
	ChildNameForm,
	ChildNamePolicy,
	LicenceStatus
} from './modules'

const DashboardChild: React.FC<Props> = ({ child, policies, refetch }) => {
	const { open, ref, toggleAccordion, height, openAccordion } = useAccordion()
	const [getChild, { data, loading }] = useLazyQuery(GET_CHILD)
	const btnText = open ? 'Hide details' : 'Show details'
	const list = transformPolicies(policies)
	const bodyClass = classNames(styles.child__body, { [styles.active]: open })
	const onShowDetails = () => {
		if (!data) {
			getChild({ variables: { id: child.id } })
		}
		toggleAccordion()
	}

	useEffect(() => {
		if (data?.child?.devices) {
			openAccordion()
		}
	}, [data])

	const isTrial = child.licence.paymentStatus === 'TRIAL' && child.licence.status !== 'EXPIRED'
	return (
		<div className={styles.child}>
			<div className={styles.child__header}>
				<div className={styles.child__left}>
					<LicenceStatus
						licenceType={child.licence.licenceType}
						isTrial={isTrial}
						daysLeft={child.licence.expirationDaysLeft}
					/>
					<p className={styles.child__name}>{child.name}</p>
				</div>
				<ChildStatus status={child.licence.status} />
			</div>
			<div className={bodyClass} ref={ref} style={{ maxHeight: `${height}` }}>
				<div className={styles.child__details}>
					<ChildInfo licence={child.licence} />
					<ChildNameForm childName={child.name} childId={child.id} refetch={refetch} />
					<ChildNamePolicy
						policy={child.policy}
						list={list}
						childId={child.id}
						refetch={refetch}
					/>
					<ChildChillTime
						chillTime={child.recreationTime}
						childId={child.id}
						refetch={refetch}
						canReset={child.canResetRecreationTime}
					/>
					<ChildDevices child={data?.child} loading={loading} />
				</div>
			</div>
			<button className={styles.child__showDetails} onClick={onShowDetails}>
				{btnText} <i className={`icon-chevron-${open ? 'up' : 'down'}`} />
			</button>
		</div>
	)
}

export default DashboardChild
type Props = {
	child: TChild['child']
	policies: TPolicy[]
	refetch: any
}
