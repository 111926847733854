import Modal from 'src/components/elements/Modal'
import { useToggle } from 'react-use'
import { TChild } from 'src/graphql/types'
import styles from '../DashboardChild.module.scss'
import ChildModal from './ChildModal'
import Loader from '../../Loader'
import ChildPart from './ChildPart'

type ChildDevicesProps = {
	child: TChild['child']
	loading: boolean
}
const LoadingDevice = () => {
	return (
		<div className={styles.device}>
			<div className={styles.device__loading}>
				<Loader color="grey" center width="38px" />
			</div>
		</div>
	)
}
export const Device: React.FC<{ title: string; subtitle: string }> = ({ title, subtitle }) => {
	return (
		<div className={styles.device}>
			<div className={styles.device__name}>{title}</div>
			<div className={styles.device__connected}>{subtitle}</div>
		</div>
	)
}
const ChildDevices: React.FC<ChildDevicesProps> = ({ child, loading }) => {
	const [show, toggle] = useToggle(false)
	const correctDevices = child?.devices?.filter((item) => item.id !== '__UNIDENTIFIED__')
	const opts = {
		onClick: toggle,
		btnText: 'Add device/Show ID',
		title: 'Devices list'
	}
	return (
		<>
			<ChildPart opts={opts}>
				{loading ? <LoadingDevice /> : null}
				{correctDevices?.map((item) => {
					const device = {
						title: item.model || item.name,
						subtitle: `Connected on ${item.connectedDate}`
					}
					return <Device key={item.id} {...device} />
				})}
				{!loading && !correctDevices?.length && (
					<div className={styles.devices__noDevices}>No devices</div>
				)}
			</ChildPart>
			{show && (
				<Modal show={show} toggleModal={toggle} title="Adding a new device">
					<ChildModal child={child} toggleModal={toggle} />
				</Modal>
			)}
		</>
	)
}

export default ChildDevices
