import { Routes, Route } from 'react-router-dom'
import routes from './router'

const App = () => {
	return (
		<Routes>
			{routes.map(({ path, Element }) => {
				return <Route path={path} element={<Element />} key={path} />
			})}
		</Routes>
	)
}

export default App
