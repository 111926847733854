import deleteIcon from 'src/assets/images/icons/delete.svg'
import FieldInput from 'src/components/elements/FieldInput'
import styles from '../../AddLicense.module.scss'

const AdditionalField: React.FC<{
	deleteField: (index: number) => void
	name: string
	onBlur: () => void
	error?: any
	index: number
}> = ({ deleteField, name, onBlur, index, error }) => {
	const onDeleteField = () => {
		deleteField(index)
	}

	return (
		<div className={styles.license__addBlock}>
			<FieldInput
				placeholder="Type identifier"
				name={name}
				connectForm
				className={styles.license__addInput}
				onBlur={onBlur}
				errors={error}
				required
			/>
			<button className={styles.license__delete} type="button" onClick={onDeleteField}>
				<img src={deleteIcon} alt="Delete" title="Delete identifier" />
			</button>
		</div>
	)
}

export default AdditionalField
