import { useQuery } from '@apollo/client'
import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useReducer } from 'react'
import { IS_AUTHENTICATED } from 'src/graphql/query'

type Payload = {
	user?: any
}
type Action =
	| { type: 'LOGIN'; payload: Payload }
	| { type: 'LOGOUT' }
	| { type: 'LOADING'; payload: boolean }
type Dispatch = (action: Action) => void
type State = {
	user: any
	loading: boolean
}
type UserProviderProps = { children: ReactNode }

const UserStateContext = createContext<{ state: State } | undefined>(undefined)
const UserDispatchContext = createContext<{ dispatch: Dispatch } | undefined>(undefined)

const initialState: State = {
	user: {
		isAuthenticated: false
	},
	loading: true
}
const reducer = (state: State, action: Action) => {
	switch (action.type) {
		case 'LOGIN':
			window.localStorage.setItem('isAuth', JSON.stringify(action.payload))
			return { ...state, ...action.payload }
		case 'LOADING':
			return { ...state, loading: action.payload }

		case 'LOGOUT':
			window.localStorage.removeItem('isAuth')
			return {
				...state,
				user: {
					isAuthenticated: false
				}
			}

		default:
			throw new Error(`Unknown action: ${action}`)
	}
}

export const ContextProvider: FC = ({ children }: UserProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	const { data, loading } = useQuery(IS_AUTHENTICATED, {
		fetchPolicy: 'cache-first'
	})
	const dispatchFn = useMemo(() => ({ dispatch }), [dispatch])
	const getState = useMemo(() => ({ state }), [state])

	const syncLogout = (event) => {
		// Detect when User login and logout, after then we try update other browser tob,
		// it does not work when opened the same page
		if (event.key === 'isAuth') {
			const isAuth = JSON.parse(window.localStorage.getItem('isAuth'))
			if (isAuth?.user?.isAuthenticated) {
				dispatch({
					type: 'LOGIN',
					payload: isAuth
				})
			} else {
				dispatch({
					type: 'LOGOUT'
				})
			}
		}
	}

	useEffect(() => {
		dispatch({
			type: 'LOADING',
			payload: loading
		})
	}, [loading])

	useEffect(() => {
		if (data?.user?.isAuthenticated) {
			dispatch({
				type: 'LOGIN',
				payload: {
					user: data?.user
				}
			})
		} else {
			dispatch({
				type: 'LOGOUT'
			})
		}

		window.addEventListener('storage', syncLogout)
		return () => {
			window.addEventListener('storage', syncLogout)
		}
	}, [data])

	return (
		<UserDispatchContext.Provider value={dispatchFn}>
			<UserStateContext.Provider value={getState}>{children}</UserStateContext.Provider>
		</UserDispatchContext.Provider>
	)
}

export const useUser = () => useContext<{ state: State }>(UserStateContext)
export const useDispatchUser = () => useContext<{ dispatch: Dispatch }>(UserDispatchContext)
