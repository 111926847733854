import classNames from 'classnames'
import styles from './Loader.module.scss'

type Props = {
	width?: string
	color?: 'black' | 'white' | 'grey'
	center?: boolean
}
const Loader: React.FC<Props> = ({ width = '24px', center, color = 'white' }) => {
	const getClass = classNames(styles.wrapper, { [styles.center]: center })
	const getValueClass = classNames(styles.loader__value, styles[color])
	return (
		<div className={getClass} style={{ width }}>
			<svg className={styles.loader} viewBox="0 0 24 24">
				<circle className={getValueClass} cx="12" cy="12" r="10" />
			</svg>
		</div>
	)
}

export default Loader
