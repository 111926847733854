import DashboardTabs from 'src/components/modules/DashboardTabs'
import Header from 'src/components/modules/Header'
import { useUser } from 'src/context/auth-context'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import WithCredentials from 'src/hoc/withCredentials'
import { GET_INPROGRESS_LICENCES } from 'src/graphql/query'
import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router'
import ROUTES_NAME from 'src/router/routesNames'

const Dashboard = () => {
	const { renderComponent } = useGlobalBadgeContext()
	const { state } = useUser()
	const navigate = useNavigate()
	const { data: licences } = useQuery(GET_INPROGRESS_LICENCES, {
		fetchPolicy: 'network-only'
	})
	useEffect(() => {
		if (licences?.user?.licences?.length > 0) {
			navigate(ROUTES_NAME.STEP2)
		}
	}, [licences])
	return (
		<>
			<Header type="profile" />
			<div className="dashboard">
				<div className="container">
					{renderComponent()}
					<div className="dashboard__top">
						<div>
							<span className="dashboard__hello">Hello, {state.user.firstName}!</span>
							<h2 className="dashboard__title">Dashboard</h2>
						</div>
					</div>
					<DashboardTabs />
				</div>
			</div>
		</>
	)
}

export default WithCredentials(Dashboard)
