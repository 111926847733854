import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useReducer } from 'react'

type Payload = {
	steps: number
}
type Action = { type: 'DELETE_STEPS' } | { type: 'SET_STEPS'; payload: Payload }
type Dispatch = (action: Action) => void
type State = {
	steps: number
}
type UserProviderProps = { children: ReactNode }

const StepsStateContext = createContext<{ state: State } | undefined>(undefined)
const StepsDispatchContext = createContext<{ dispatch: Dispatch } | undefined>(undefined)

const initialState: State = {
	steps: null
}
const reducer = (state: State, action: Action) => {
	switch (action.type) {
		case 'SET_STEPS':
			window.localStorage.setItem('steps', JSON.stringify(action.payload.steps))
			return { ...state, steps: action.payload.steps }

		case 'DELETE_STEPS':
			window.localStorage.removeItem('steps')
			return {
				...state,
				steps: null
			}

		default:
			throw new Error(`Unknown action: ${action}`)
	}
}

export const StepsContextProvider: FC = ({ children }: UserProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	const dispatchFn = useMemo(() => ({ dispatch }), [dispatch])
	const getState = useMemo(() => ({ state }), [state])

	const syncLogout = (event) => {
		if (event.key === 'steps') {
			const steps = JSON.parse(window.localStorage.getItem('steps'))
			if (steps) {
				dispatch({
					type: 'SET_STEPS',
					payload: { steps }
				})
			} else {
				dispatch({
					type: 'DELETE_STEPS'
				})
			}
		}
	}

	useEffect(() => {
		const steps = JSON.parse(window.localStorage.getItem('steps'))
		if (steps) {
			dispatch({
				type: 'SET_STEPS',
				payload: { steps }
			})
		} else {
			dispatch({
				type: 'DELETE_STEPS'
			})
		}
		window.addEventListener('storage', syncLogout)
		return () => {
			window.addEventListener('storage', syncLogout)
		}
	}, [])

	return (
		<StepsDispatchContext.Provider value={dispatchFn}>
			<StepsStateContext.Provider value={getState}>{children}</StepsStateContext.Provider>
		</StepsDispatchContext.Provider>
	)
}

export const useSteps = () => useContext<{ state: State }>(StepsStateContext)
export const useDispatchSteps = () => useContext<{ dispatch: Dispatch }>(StepsDispatchContext)
