import React, { useState, createContext, useContext, useMemo } from 'react'
import Badge from 'src/components/elements/Badge'

type GlobalBadgeContext = {
	showModal: (modalType: string, modalProps?: any) => void
	hideModal: () => void
	renderComponent: () => React.ReactNode
	store: any
}

const initialState: GlobalBadgeContext = {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	showModal: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	hideModal: () => {},
	store: {},
	renderComponent: () => null
}

const GlobalBadgeContext = createContext(initialState)
export const useGlobalBadgeContext = () => useContext(GlobalBadgeContext)

export const GlobalBadge: React.FC = ({ children }) => {
	const [store, setStore] = useState<{ modalType: string; modalProps?: any }>({
		modalType: null,
		modalProps: null
	})
	const [show, toggle] = useState(false)

	const { modalType, modalProps } = store

	// eslint-disable-next-line no-shadow
	const showModal = (modalType: string, modalProps: any = {}) => {
		setStore({
			...store,
			modalType,
			modalProps
		})
		toggle(true)
	}

	const hideModal = () => {
		setStore({
			...store,
			modalType: null,
			modalProps: {}
		})
		toggle(false)
	}

	const renderComponent = () => {
		if (!modalType) {
			return null
		}
		return <Badge {...modalProps} show={show} toggleShow={hideModal} />
	}
	const badgeState = useMemo(
		() => ({ store, showModal, hideModal, renderComponent }),
		[store, showModal, hideModal, renderComponent]
	)
	return <GlobalBadgeContext.Provider value={badgeState}>{children}</GlobalBadgeContext.Provider>
}
