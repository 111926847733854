export const passwordData = [
	{
		name: 'oldPassword',
		label: 'Current password',
		placeholder: 'Type current password',
		isPassword: true,
		connectForm: true
	},
	{
		name: 'password1',
		label: 'New password',
		placeholder: 'Type new password',
		isPassword: true,
		connectForm: true
	},
	{
		name: 'password2',
		label: 'Confirm password',
		placeholder: 'Type password again',
		isPassword: true,
		connectForm: true
	}
]

export const emailData = [
	{
		name: 'email',
		type: 'email',
		label: 'New email',
		placeholder: 'Type new email',
		connectForm: true
	},
	{
		name: 'password',
		label: 'Current password',
		placeholder: 'Type current password',
		isPassword: true,
		connectForm: true
	}
]
