import { useState, useEffect } from 'react'
import { isEmptyObject } from '../utils/index'

const useMultiErrors = (errors) => {
	const [errorsPack, setErrors] = useState<{ [key: string]: string }>({})

	useEffect(() => {
		if (!isEmptyObject(errors)) {
			setErrors(errors)
		}
	}, [errors])

	return {
		setErrors,
		errorsPack
	}
}
export default useMultiErrors
