import { useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useToggle } from 'react-use'
import Loader from 'src/components/elements/Loader'
import { copyTextToClipboard } from 'src/utils'
import { PDFLink } from 'src/utils/links'
import styles from './AddChildAccess.module.scss'

const AddChildAccess: React.FC<{
	childName: string
	pin: string
	noTitle?: boolean
}> = ({ childName, pin, noTitle }) => {
	const [open, toggle] = useToggle(false)
	const copyPinCode = async () => {
		try {
			await copyTextToClipboard(pin)
			toggle()
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		let timer
		if (open) {
			timer = setTimeout(() => {
				toggle(false)
			}, 3000)
		}
		return () => {
			clearTimeout(timer)
		}
	}, [open])
	return (
		<div className={styles.access}>
			{!noTitle && (
				<div className={styles.access__title}>
					Follow these steps to activate the policy on your child’s device
				</div>
			)}
			<div className={styles.access__step}>step • 1</div>
			<div className={styles.access__stepText}>
				Scan the QR-code using the camera on {childName}'s device to download the app
			</div>
			<Tabs
				selectedTabClassName={styles.access__tabActive}
				forceRenderTabPanel
				className={styles.access__tabs}
			>
				<TabList className={styles.access__tablist}>
					<Tab className={styles.access__tab}>for iOS</Tab>
					<Tab className={styles.access__tab}>for Android</Tab>
				</TabList>

				<TabPanel
					selectedClassName={styles.access__panelActive}
					className={styles.access__panel}
				>
					<img src="/images/ios-qr.png" alt="" />
				</TabPanel>
				<TabPanel
					selectedClassName={styles.access__panelActive}
					className={styles.access__panel}
				>
					<img src="/images/android-qr.png" alt="" />
				</TabPanel>
			</Tabs>
			<div className={styles.another}>
				<div className={styles.another__separator}>or visit</div>
				<a href={PDFLink} target="_blank" className={styles.another__link} rel="noreferrer">
					Full installation guide
				</a>
			</div>
			<div className={styles.access__step}>step • 2</div>
			<div className={styles.access__stepText}>
				Copy this Configuration ID to login to the NextDNS application
			</div>
			<div className={styles.access__pin}>
				{open && <div className={styles.copy}>Copied!</div>}
				<div className={styles.access__pinCode}>
					{pin || <Loader color="grey" center width="24px" />}
				</div>
				<button
					disabled={!pin}
					type="button"
					className={`${styles.access__pinIcon} icon-copy`}
					aria-label="Copy code"
					onClick={copyPinCode}
				/>
			</div>
		</div>
	)
}

export default AddChildAccess
