import { FormProvider, useForm } from 'react-hook-form'
import { AddChild, AddChildHeader, AddChildForm } from 'src/components/modules/AddChild'
import { capitalizeString, serializeErrorsOnSteps, transformPolicies } from 'src/utils'
import { GET_INPROGRESS_LICENCES, GET_POLICIES } from 'src/graphql/query'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_CHILD } from 'src/graphql/mutation'
import { useNavigate } from 'react-router-dom'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import ROUTES_NAME from 'src/router/routesNames'
import Header from 'src/components/modules/Header'
import { Progress } from 'src/components/modules/Header/modules'
import WithCredentials from 'src/hoc/withCredentials'
import { useState } from 'react'
import Modal from 'src/components/elements/Modal'
import Button from 'src/components/elements/Button'
import { FormButton } from '../Step2/Form'
import styles from './DashboardCreateChild.module.scss'

const DashboardCreateChild = () => {
	const methods = useForm({
		mode: 'onSubmit'
	})
	const navigate = useNavigate()
	const { showModal, renderComponent } = useGlobalBadgeContext()
	const [showAgreement, setShowAgreement] = useState(null)
	const isOpen = Boolean(showAgreement)
	const { handleSubmit, control, watch } = methods
	const { data: policies, loading } = useQuery(GET_POLICIES)
	const { data: licences } = useQuery(GET_INPROGRESS_LICENCES, {
		fetchPolicy: 'network-only'
	})
	const [createChild, { loading: loadingBtn }] = useMutation(CREATE_CHILD, {
		onCompleted: (data) => {
			if (data.createChild.ok) {
				setShowAgreement(null)
				navigate(ROUTES_NAME.DASHBOARD)
				return
			}
			if (data.createChild.agreement) {
				setShowAgreement(data.createChild.agreement)
			} else {
				window.scrollTo(0, 0)

				const errors = data.createChild.errors.map((item) => {
					return item.childErrors.filter((child) => child.fieldName !== 'agreed')
				})
				const err = serializeErrorsOnSteps(errors)

				showModal('error', {
					color: 'error',
					text: err,
					time: 30000
				})
			}
		}
	})

	const list = transformPolicies(policies?.policies)
	const onSubmit = handleSubmit((data) => {
		const children = licences.user.licences.map((item, index) => {
			return {
				name: capitalizeString(data[`childName${index}`]),
				policyId: data[`policy${index}`].value,
				licenceCode: item.code
			}
		})
		createChild({ variables: { children } })
	})

	const onSubmitAgreement = () => {
		const data = watch()
		const children = licences.user.licences.map((item, index) => {
			return {
				name: capitalizeString(data[`childName${index}`]),
				policyId: data[`policy${index}`].value,
				licenceCode: item.code,
				agreed: true
			}
		})
		createChild({ variables: { children } })
	}

	const closeModal = () => setShowAgreement(null)
	return (
		<>
			<Header type="profile" />

			<FormProvider {...methods}>
				<form onSubmit={onSubmit} className="step__form">
					<div className="container container-md-center container-lg-center">
						{renderComponent()}
						<Progress step={3} className="dashboard-step__progress" position="top" />
						<div className="step__titles step__md_mt38">
							{licences?.user?.licences.length ? (
								<>
									<h2 className="step__title">Create your child’s profile</h2>
									<p className="step__subtitle">
										Add a <strong>name</strong> and select the appropriate{' '}
										<strong>policy</strong>
									</p>
								</>
							) : (
								<h2 className="step__title">
									You do not have unactivated licences
								</h2>
							)}
						</div>
						<div className="step__desktopGrid">
							{licences?.user?.licences?.map((license, index) => (
								<AddChild key={license.code}>
									<AddChildHeader
										index={index}
										status={license.status}
										type="ACTIVE"
									/>
									<AddChildForm
										control={control}
										selectName={`policy${index}`}
										inputName={`childName${index}`}
										loading={loading}
										list={list}
									/>
								</AddChild>
							))}
						</div>
					</div>

					<FormButton licences={licences?.user?.licences} loading={loadingBtn} />
				</form>
			</FormProvider>
			{isOpen && (
				<Modal show={isOpen} toggleModal={closeModal} title="Confirm agreement">
					<div>
						<div
							className={styles.extend}
							dangerouslySetInnerHTML={{ __html: showAgreement }}
						/>
						<div className={styles.buttons}>
							<Button color="empty" onClick={closeModal}>
								Cancel
							</Button>
							<Button type="submit" color="primary" onClick={onSubmitAgreement}>
								Confirm
							</Button>
						</div>
					</div>
				</Modal>
			)}
		</>
	)
}

export default WithCredentials(DashboardCreateChild)
