import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

const FormCounter: React.FC<{
	connectForm?: boolean
	name: string
	onChange?: (time: number) => void
	className?: string
}> = ({ connectForm, name, className }) => {
	const [count, setCount] = useState<number>(0)
	const { register, setValue } = useFormContext()

	const onDecrement = () => {
		setCount(count - 1)
	}
	const onIncrement = () => {
		setCount(count + 1)
	}

	useEffect(() => {
		if (connectForm) {
			setValue(name, count)
		}
	}, [count])

	const classname = classNames('counter', className)
	return (
		<div className={classname}>
			<button
				disabled={count === 0}
				onClick={onDecrement}
				type="button"
				className="icon-minus"
				aria-label="Minus"
			/>
			<div className="counter__count">{count}</div>
			{connectForm ? (
				<input type="hidden" {...register(name)} value={count} />
			) : (
				<input type="hidden" name={name} value={count} />
			)}
			<button onClick={onIncrement} type="button" className="icon-plus" aria-label="Plus" />
		</div>
	)
}

export default FormCounter
