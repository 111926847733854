import Button from 'src/components/elements/Button'
import { AddChildAccess } from 'src/components/modules/AddChild'
import { TChild } from 'src/graphql/types'
import styles from '../DashboardChild.module.scss'

const ChildModal: React.FC<{ toggleModal: () => void; child: TChild['child'] }> = ({
	toggleModal,
	child
}) => {
	return (
		<>
			<AddChildAccess childName={child.name} pin={child.licence.profileCode} noTitle />
			<div className={styles.buttons}>
				<Button color="empty" onClick={toggleModal}>
					Cancel
				</Button>
				<Button type="submit" color="primary" onClick={toggleModal}>
					Confirm
				</Button>
			</div>
		</>
	)
}

export default ChildModal
