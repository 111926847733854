import BlockTitle from 'src/components/elements/BlockTitle'
import arrow from 'src/assets/images/icons/arrow-right.svg'
import styles from './PolicyBlock.module.scss'

const PolicyBlock = () => {
	return (
		<div className={styles.block}>
			<div className="container">
				<BlockTitle color="black">
					We promote safe and balanced use of technology
				</BlockTitle>
				<p className={styles.block__subtitle}>
					SchoolsMobile’s mission is to give every school and parent an easy way to
					implement a good usage policy for children to keep them safe, engaged and
					positive about technology.
				</p>
				<a className={styles.link} href="https://schoolsmobile.com/">
					Read more <img src={arrow} alt="" className={styles.block__arrow} />
				</a>
			</div>
		</div>
	)
}

export default PolicyBlock
