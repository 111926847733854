import { useController, useFormContext } from 'react-hook-form'
import React from 'react'

const ConnectForm = ({ children, name }) => {
	const methods = useFormContext()

	const { field } = useController({
		name,
		control: methods.control
	})

	if (React.Children.only(children)) {
		return React.cloneElement(children, {
			...children.props,
			control: methods.control,
			setValue: methods.setValue,
			value: field.value,
			field
		})
	}
	return null
}

export default ConnectForm
