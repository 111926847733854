export const phoneMaskRegExp = /^\+\d{8,16}$/
export const serializePhone = (phone: string) => {
	if (!phone) return {}
	return {
		phoneNumber: phone.replace(/\s|[(]|[)]|[-]/gm, '')
	}
}

export const emptyStringToNull = (value, originalValue) => {
	if (typeof originalValue === 'string' && originalValue === '') {
		return null
	}
	return value
}

export const isNullOrUndefined = (value) => {
	return value === null || value === undefined
}

export const isEmptyObject = (obj) => {
	// eslint-disable-next-line
	for (let prop in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, prop)) {
			return false
		}
	}
	return true
}

export const copyTextToClipboard = async (text) => {
	if ('clipboard' in navigator) {
		return navigator.clipboard.writeText(text)
	}
	return document.execCommand('copy', true, text)
}

export const transformPolicies = (policies: any) => {
	return policies?.map((policy: any) => {
		return {
			label: policy?.name,
			value: policy?.id
		}
	})
}

export const transformCountries = (data: any) =>
	data?.map((item: any) => {
		return {
			label: item.name,
			value: item.id,
			icon: item.icon
		}
	})

export const transformCities = (data: any) =>
	data?.map((item: any) => {
		return {
			label: item.name,
			value: item.id
		}
	})

export function capitalizeString(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

const censorWord = (str: string): string => {
	return str.substring(0, 2) + '*'.repeat(str.length - 2)
}

export const censorEmail = (email: string) => {
	let arr = email.split('@')
	return `${censorWord(arr[0])}*${arr[1]}`
}
export const serializeErrors = (errors: any) => {
	return errors
		.map((err) => {
			return {
				[err.fieldName]: {
					message: err.errors[0]
				}
			}
		})
		.reduce((acc, cur) => ({ ...acc, ...cur }), {})
}

export const serializeErrorsOnSteps = (errors: any) => {
	const a = errors.filter((item) => item?.childErrors.length > 0)[0]
	const b = a?.childErrors.map((item) => item.errors[0]).toString()
	return b
}

export const filterArrayValues = (values) => {
	const array = 'array' in values ? values.array : null
	if (!array) return []
	const hasValues = array.filter((item) => item.identifier !== '')
	const codes = [values.identifier].concat(hasValues.map((item) => item.identifier))
	return codes
}

type TTimeValue = {
	hours: number
	minutes: number
}

export const compareTime = (time: TTimeValue, maxTime: TTimeValue): boolean => {
	const { hours, minutes } = time
	const { hours: maxHours, minutes: maxMinutes } = maxTime
	const totalMinutes = minutes + hours * 60
	const totalMaxMinutes = maxMinutes + maxHours * 60
	return totalMinutes <= totalMaxMinutes
}

export const addZero = (time: number): number | string => {
	if (time.toString().length === 1 && time < 10) {
		return `0${time}`
	}
	return time
}
