import { useMedia } from 'react-use'
import styles from '../DashboardChild.module.scss'

const licence = {
	PRE_PAID: 'Pre-paid',
	EXTRA: 'Extra',
	TRIAL: 'Extra'
}

const LicenceStatus = ({
	licenceType,
	daysLeft,
	isTrial
}: {
	licenceType: string
	daysLeft: number
	isTrial: boolean
}) => {
	const isDesktop = useMedia('(min-width: 768px)')
	const trialDays = `${daysLeft} ${isDesktop ? 'days' : 'd.'} left`
	return (
		<div className={styles.child__school}>
			<div className={styles.child__licence}>{licence[licenceType]} licence</div>
			{isTrial && (
				<div className={styles.child__trial}>
					<span>{trialDays}</span> <i className={`icon-time ${styles.child__icon}`} />
				</div>
			)}
		</div>
	)
}
export default LicenceStatus
