import { useRef, useState } from 'react'
import { useToggle } from 'react-use'

const useAccordion = (initialHeight?: string) => {
	const [open, toggleOpen] = useToggle(false)
	const content = useRef()
	const initHeight = initialHeight ?? '0px'
	const [height, setHeight] = useState(initHeight)

	const toggleAccordion = () => {
		toggleOpen()
		// @ts-ignore
		setHeight(open ? initHeight : `${content.current.scrollHeight}px`)
	}

	const openAccordion = () => {
		toggleOpen(true)
		// @ts-ignore
		setHeight(`${content.current.scrollHeight}px`)
	}

	return {
		height,
		ref: content,
		open,
		openAccordion,
		toggleAccordion
	}
}
export default useAccordion
