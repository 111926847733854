import { TPolicy } from 'src/graphql/types'
import { UPDATE_CHILD } from 'src/graphql/mutation'
import { useMutation } from '@apollo/client'
import { useState } from 'react'
import CustomSelect from '../../CustomSelect'
import styles from '../DashboardChild.module.scss'
import Modal from '../../Modal'
import Button from '../../Button'

type Props = {
	policy: TPolicy
	list: [{ value: string; label: string }]
	childId: string
	refetch: any
}
const ChildNamePolicy: React.FC<Props> = ({ policy, list, childId, refetch }) => {
	const defaultValue = {
		value: policy.id,
		label: policy.name
	}
	const [showAgreement, setShowAgreement] = useState(null)
	const [value, setValue] = useState(defaultValue)
	const isOpen = Boolean(showAgreement)
	const [updateChild] = useMutation(UPDATE_CHILD, {
		onCompleted: async (data) => {
			if (data.updateChild.ok) {
				setShowAgreement(null)
				await refetch()
			} else {
				const error = data.updateChild.errors.find((item) => item.fieldName === 'agreed')
				if (error) {
					setShowAgreement(error.errors.join(' '))
				}
			}
		}
	})

	const onChange = (_, val) => {
		setValue(val)
		updateChild({
			variables: {
				childId,
				policyId: val.value
			}
		})
	}
	const onSubmitAgreement = () => {
		updateChild({
			variables: {
				childId,
				policyId: value.value,
				agreed: true
			}
		})
	}

	const closeModal = () => {
		setValue(defaultValue)
		setShowAgreement(null)
	}
	return (
		<div className={styles.field}>
			<CustomSelect
				defaultValue={defaultValue}
				name="policy"
				value={value}
				onChange={onChange}
				list={list}
				label="Policy"
				className={styles.field__input}
			/>
			<span className={styles.field__disclaimer}>
				Select a different policy, if necessary.
			</span>
			{isOpen && (
				<Modal show={isOpen} toggleModal={closeModal} title="Confirm agreement">
					<div>
						<div
							className={styles.extend__text}
							dangerouslySetInnerHTML={{ __html: showAgreement }}
						/>
						<div className={styles.buttons}>
							<Button color="empty" onClick={closeModal}>
								Cancel
							</Button>
							<Button type="submit" color="primary" onClick={onSubmitAgreement}>
								Confirm
							</Button>
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default ChildNamePolicy
