import styles from '../DashboardChild.module.scss'

const ChildPart = ({ children, opts }) => {
	const { onClick, btnText, title } = opts
	return (
		<div className={styles.devices}>
			<div className={styles.devices__titles}>
				<div className={styles.devices__label}>{title}</div>
				<button className={styles.devices__btn} onClick={onClick}>
					{btnText}
				</button>
			</div>
			<div className={styles.devices__wrapper}>{children}</div>
		</div>
	)
}

export default ChildPart
