import Header from 'src/components/modules/Header'

const NoFound = () => {
	return (
		<div>
			<Header />
			<div className="notFound">404 Page Not Found</div>
		</div>
	)
}

export default NoFound
