import { Link } from 'react-router-dom'
import links, { termsAndPolicyLinks } from 'src/utils/links'
import styles from '../Footer.module.scss'

const FooterLinks = ({ title, hrefs }) => {
	return (
		<div className={styles.desktop__part}>
			<strong className={styles.footer__title}>{title}</strong>
			<ul>
				{hrefs.map((link) => (
					<li key={link.title} className={styles.footer__link}>
						<Link to={link.url}>{link.title}</Link>
					</li>
				))}
			</ul>
		</div>
	)
}
const Footer = () => {
	const show = false
	return (
		<div className={styles.desktop}>
			<div className="container">
				<div className={styles.desktop__top}>
					<div className={styles.desktop__logo}>
						<img src="/images/logotype.png" alt="" className={styles.desktop__image} />
					</div>
					{show && (
						<div className={styles.desktop__links}>
							<FooterLinks title="About us" hrefs={links.aboutUs} />
							<FooterLinks title="Learn" hrefs={links.learn} />
						</div>
					)}
				</div>
				<div className={styles.desktop__footer}>
					<p className={styles.desktop__copyright}>
						© 2022 School Mobile. All rights reserved.
					</p>
					<div className={styles.desktop__policy}>
						<a href={termsAndPolicyLinks.policy.link} className={styles.desktop__link}>
							{termsAndPolicyLinks.policy.title}
						</a>
						<a href={termsAndPolicyLinks.terms.link} className={styles.desktop__link}>
							{termsAndPolicyLinks.terms.title}
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
