import { Tooltip, AreaChart, Area } from 'recharts'
import { useEffect, useRef, useState } from 'react'

export const getSpecificStatus = (arr: DataItem[], name: string) => {
	return arr?.find((item) => {
		if (item.name === name) {
			return item
		}
		return null
	})?.queries
}
const formatData = (
	allData: ReportingGraphProps['data'],
	interval: string
): Array<{ name: string; queries: number[]; blocked: number[] }> => {
	if (!allData?.times.length || !allData?.queries.length) {
		return []
	}
	const dates = allData.times.map((item: string) => {
		const dateFormat = new Date(item)
		const time = new Intl.DateTimeFormat('en-GB', {
			hour: 'numeric',
			minute: '2-digit'
		}).format(dateFormat)
		const date = dateFormat.toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'long',
			day: '2-digit'
		})
		if (interval === '-30d' || interval === '-3M') {
			return {
				name: date
			}
		}
		return {
			name: `${date} ${time}`
		}
	})

	const all_queries = getSpecificStatus(allData.queries, 'total')
	const blocked = getSpecificStatus(allData.queries, 'blocked')

	return dates.map((item: { name: string }, i: number) => {
		return {
			...item,
			queries: all_queries[i],
			blocked: blocked ? blocked[i] : []
		}
	})
}
const renderTooltipContent = ({ active, payload }) => {
	if (active && payload && payload.length) {
		return (
			<div className="graph-custom-tooltip">
				<div className="graph-custom-tooltip__date">{payload[0].payload.name}</div>
				<div className="graph-custom-tooltip__queries">
					<span className="graph-custom-tooltip__granted">
						{payload[0].payload.queries}
					</span>{' '}
					queries
				</div>
				<div className="graph-custom-tooltip__queries">
					<span className="graph-custom-tooltip__blocked">
						{payload[0].payload.blocked}
					</span>{' '}
					blocked queries
				</div>
			</div>
		)
	}

	return null
}

const ReportingGraph = ({ data, interval }: ReportingGraphProps) => {
	const [height, setHeight] = useState(0)
	const [width, setWidth] = useState(0)
	const ref = useRef(null)

	useEffect(() => {
		setHeight(ref.current.clientHeight)
		setWidth(ref.current.clientWidth)
	}, [])

	return (
		<div className="graph">
			<div className="graph__header">
				<h3 className="graph__title">Queries</h3>
				<p className="graph__subtitle">Evolution of queries over time.</p>
			</div>
			<div className="graph__area" ref={ref}>
				<AreaChart width={width} height={height} data={formatData(data, interval)}>
					<Tooltip
						content={renderTooltipContent}
						payload={formatData(data, interval)}
						wrapperStyle={{ outline: 'none' }}
					/>
					<Area
						type="monotone"
						dataKey="queries"
						stroke="#519BDE"
						fillOpacity={1}
						fill="#E9F2FB"
					/>
					<Area
						type="monotone"
						dataKey="blocked"
						stroke="#DE4548"
						fillOpacity={1}
						fill="#FFE3E9"
					/>
				</AreaChart>
			</div>
		</div>
	)
}

export default ReportingGraph

type DataItem = {
	name: string
	queries: number[] | number
}
type ReportingGraphProps = {
	data: {
		queries: DataItem[]
		times: string[]
	}
	interval: string
}
