import { useMedia } from 'react-use'
import AddLicense from 'src/components/elements/AddLicense'
import BlockTitle from 'src/components/elements/BlockTitle'

import styles from './GetStarted.module.scss'

const GetStarted = () => {
	const isDesktop = useMedia('(min-width: 768px)')

	const text = isDesktop
		? 'Add a licence code if you have one from your school or you can buy a pay-as-you-go monthly licence for members of the family'
		: 'Add a licence code if you have one from your school'

	return (
		<div className={styles.block}>
			<div className="container">
				<div className={styles.desktop}>
					<div className={styles.desktop__titles}>
						<BlockTitle color="black">Set up in under 90 seconds!</BlockTitle>
						<p className={styles.block__subtitle}>{text}</p>
					</div>
					<AddLicense />
				</div>
			</div>
		</div>
	)
}

export default GetStarted
