import image1 from 'src/assets/images/icons/prot1.svg'
import image2 from 'src/assets/images/icons/prot2.svg'
import image3 from 'src/assets/images/icons/prot3.svg'
import image4 from 'src/assets/images/icons/prot4.svg'

const protectionData = [
	{
		id: 1,
		text: 'Malicious websites',
		image: image1
	},
	{
		id: 2,
		text: 'No Adult Content',
		image: image2
	},
	{
		id: 3,
		text: 'No Gambling',
		image: image3
	},
	{
		id: 4,
		text: 'Malware & Phishing',
		image: image4
	}
]

export default protectionData
