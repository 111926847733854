import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import { EXTEND_REC_TIME } from 'src/graphql/mutation'
import { GET_TIME_INTERVAL_LIST } from 'src/graphql/query'
import Button from '../../Button'
import CustomSelect from '../../CustomSelect'
import styles from '../DashboardChild.module.scss'
import { Device } from './ChildDevices'
import SelectWrapper from './SelectWrapper'

export type TTimeValue = {
	hours: number
	minutes: number
}

export type TChildExtendTime = {
	toggleModal: () => void
	title: string
	subtitle: string
	childId: string
	refetch: any
}

const ChildExtendTime: React.FC<TChildExtendTime> = ({
	toggleModal,
	title,
	subtitle,
	childId,
	refetch
}) => {
	const [error, setError] = useState<null | string>(null)
	const { data: list, loading } = useQuery(GET_TIME_INTERVAL_LIST, {
		variables: {
			id: childId
		}
	})
	const { showModal } = useGlobalBadgeContext()
	const methods = useForm()
	const { handleSubmit } = methods
	const listStart = list?.child?.timeIntervalList
		?.map((item) => ({ label: item, value: item, id: item }))
		.slice(0, -1)
	const listEnd = list?.child?.timeIntervalList
		?.map((item) => ({ label: item, value: item, id: item }))
		.slice(1)

	const [extendTime] = useMutation(EXTEND_REC_TIME, {
		onCompleted: async (data) => {
			toggleModal()
			if (data.setRecreationTime.ok) {
				window.scrollTo(0, 0)
				showModal('success', {
					color: 'success',
					text: 'The chill time today was successfully extended',
					time: 10000
				})
				await refetch()
			}
			if (data.setRecreationTime.errors.length) {
				window.scrollTo(0, 0)
				showModal('error', {
					color: 'error',
					text: data.setRecreationTime.errors[0].errors[0],
					time: 10000
				})
			}
		}
	})

	const onSubmit = ({ start, end }) => {
		setError(null)
		extendTime({
			variables: {
				childId,
				start: start.value,
				end: end.value
			}
		})
	}
	const onStartChange = (_, val) => {
		const index = listEnd.findIndex((item) => item.value === val?.value) + 1
		const value = listEnd.at(index)
		methods.setValue('end', value)
	}
	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.extend}>
					<div className={styles.extend__schedule}>
						<Device title={title} subtitle={subtitle} />
					</div>
					<span className={styles.extend__disclaimer}>
						Please change your chill time today as you prefer:
					</span>
					<div className={styles.extend__counters}>
						<SelectWrapper name="start" list={listStart} isLoading={loading}>
							<CustomSelect
								name="start"
								className={styles.select}
								onChange={onStartChange}
							/>
						</SelectWrapper>
						<SelectWrapper name="end" list={listEnd} watch="start" isLoading={loading}>
							<CustomSelect name="end" className={styles.select} />
						</SelectWrapper>
					</div>
					{error && <span className="formControl__error">{error}</span>}
				</div>
				<div className={styles.buttons}>
					<Button color="empty" onClick={toggleModal}>
						Cancel
					</Button>
					<Button type="submit" color="primary">
						Confirm
					</Button>
				</div>
			</form>
		</FormProvider>
	)
}

export default ChildExtendTime
