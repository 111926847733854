import {
	ApolloClient,
	ApolloLink,
	HttpLink,
	InMemoryCache,
	defaultDataIdFromObject
} from '@apollo/client'
import fetch from 'isomorphic-unfetch'
import { RetryLink } from '@apollo/client/link/retry'
import { setContext } from '@apollo/client/link/context'
import QueueLink from 'apollo-link-queue'
import { onError } from '@apollo/client/link/error'

export const isWindowContext = typeof window !== 'undefined'

const customFetch = (uri, options) => {
	const { operationName } = JSON.parse(options.body)
	return fetch(`${uri}?opname=${operationName}`, options)
}

export const createApolloClient = () => {
	const httpLink = new HttpLink({
		uri: process.env.REACT_APP_API_URL, // Server URL (must be absolute)
		fetch: customFetch, // Additional fetch() options like `credentials` or `headers`
		credentials: 'include'
	})

	const retryLink = new RetryLink({
		attempts: (count, _, error) => {
			/** This is an example how you can use retryLink for graphql request.
       @param operation.operationName - operation name graphql
       @param count - counter failed attempts
       @param error - error catcher

       if (!!error && count >= 5 && operation.operationName === 'uploadAdImage') {
					return false;
				}
       if (!!error && operation.operationName === 'uploadAdImage') {
					return true;
				}
       */

			// all other requests
			if (!!error && count <= 3) {
				return !!error
			}
			return false
		},
		delay: (count) => count * 1000 * Math.random()
	})

	/* The `ctx` (NextPageContext) will only be present on the server.
   use it to extract auth headers (ctx.req) or similar.
   const token = localStorage.getItem(AUTH_TOKEN); */
	const authLink = setContext((_, { headers }) => {
		return {
			headers: {
				...headers
			}
		}
	})

	const offlineLink = new QueueLink()
	if (isWindowContext) {
		window.addEventListener('offline', () => offlineLink.close())
		window.addEventListener('online', () => offlineLink.open())
	}

	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors) {
			graphQLErrors.forEach(({ message, locations, path }) => {
				// eslint-disable-next-line no-console
				console.log(
					`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
						locations
					)}, Path: ${path}`
				)
			})
		}
		if (networkError) {
			// eslint-disable-next-line no-console
			console.log(`[Network error]: ${networkError}`)
		}
	})

	const cache = new InMemoryCache({
		dataIdFromObject(responseObject) {
			switch (responseObject.__typename) {
				case 'ParentType':
					return `ParentType:${responseObject.firstName}`
				case 'LicenceType':
					return `LicenceType:${responseObject.code}`
				default:
					return defaultDataIdFromObject(responseObject)
			}
		},
		typePolicies: {
			ParentType: {
				fields: {
					licences: {
						merge(_, incoming) {
							return incoming
						}
					}
				}
			}
		}
	})

	const link = ApolloLink.from([retryLink, offlineLink, errorLink, authLink, httpLink])

	return new ApolloClient({
		link,
		cache
	})
}
