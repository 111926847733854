import { useRef, useState } from 'react'
import useOnClickOutside from './useOnClickOutside'

type TUseCustomSelect = {
	list: Array<any>
	defaultValue?: any
	value?: any
	isDropdown?: boolean
	isFiltered?: boolean
}

type OutTUseCustomSelect = {
	ref: any
	toggleDropdown: () => void
	filteredData?: Array<any>
	selectedValue: any
	inputValue?: string
	isShowDropdown: boolean
	chooseValue: (val: any) => void
	filterHandler?: (event: { target: { value: string } }) => void
}

const useCustomSelect = (props: TUseCustomSelect): OutTUseCustomSelect => {
	const { list = [], defaultValue, isDropdown = false } = props
	const ref = useRef(null)
	const checkDefaultValue = () => {
		if (defaultValue) {
			return defaultValue
		}
		if (list.length) {
			return list[0]
		}
		return {}
	}

	const [selectedValue, setValue] = useState(checkDefaultValue())

	const [data, setData] = useState(list)
	const [inputValue, setInputValue] = useState<string>('')
	const [isShowDropdown, setShow] = useState<boolean>(false)

	const filterHandler = (event: { target: { value: string } }): void => {
		const { value: nativeValue } = event.target
		if (list.length) {
			const filtered = list.filter((el) => {
				return el?.label
					.toString()
					.toLowerCase()
					.includes(nativeValue?.toString().toLowerCase())
			})
			setInputValue(nativeValue)
			setData(filtered)
		}
	}

	useOnClickOutside(ref, () => setShow(false))

	function chooseValue(val: any) {
		if (!val?.value) {
			const result = data.find((item) => {
				if (item?.value) {
					return item.value === val
				}

				return item === val
			})

			if (result) {
				setValue(result)
				if (isDropdown) {
					setShow(false)
				}
			}
		} else {
			setValue(val)
			if (isDropdown) {
				setShow(false)
			}
		}
	}

	function toggleDropdown() {
		setShow(!isShowDropdown)
	}

	return {
		selectedValue,
		toggleDropdown,
		chooseValue,
		isShowDropdown,
		ref,
		filteredData: inputValue ? data : list,
		filterHandler,
		inputValue
	}
}

export default useCustomSelect
