import classnames from 'classnames'
import useCustomSelect from 'src/hooks/useCustomSelect'
import DotLoader from 'src/components/elements/DotLoader'
import { useEffect } from 'react'
import styles from './CustomSelect.module.scss'
import { ISearchPopupCustomSelect } from './CustomSelect.types'

const CustomSelect: React.FC<ISearchPopupCustomSelect> = (props) => {
	const {
		defaultValue,
		value,
		list,
		label,
		name,
		isDisabled,
		onChange,
		isLoading,
		error,
		field,
		toggleClassname,
		labelClassname,
		className,
		withSearch
	} = props

	const {
		isShowDropdown,
		toggleDropdown,
		chooseValue,
		selectedValue,
		ref,
		filteredData,
		filterHandler,
		inputValue = ''
	} = useCustomSelect({
		list,
		defaultValue,
		isDropdown: true,
		isFiltered: true
	})

	const onChooseValue = (val, toggle = true) => {
		if (val) {
			if (onChange) {
				onChange(name, val)
			}
			chooseValue(val)
		}
		if (toggle) {
			toggleDropdown()
		}
	}
	useEffect(() => {
		if (field) {
			field.onChange(selectedValue)
		}
	}, [selectedValue])

	const dropClass = classnames(styles.customSelect__drop, {
		[styles.open]: isShowDropdown
	})
	const defaultVal = value || selectedValue || defaultValue

	const selectToggle = classnames(styles.customSelect__toggle, toggleClassname, {
		[styles.customSelect__toggleDisabled]: isDisabled,
		[styles.customSelect__active]: isShowDropdown,
		[styles.customSelect__hasValue]: defaultVal.value !== 'default',
		[styles.customSelect__error]: error?.[name],
		[styles.customSelect__hasIcon]: defaultVal?.icon
	})

	const getClassLi = (el) =>
		classnames(styles.customSelect__btn, {
			[styles.customSelect__btnSelected]:
				el.value === defaultVal?.value ||
				(!selectedValue?.value && el.value === defaultValue?.value)
		})

	const classSelect = classnames(styles.customSelect, className, { [styles.loading]: isLoading })

	const classLabel = classnames(styles.customSelect__label, labelClassname)

	return (
		<div className={classSelect} ref={ref}>
			{label && (
				<label className={classLabel} htmlFor={name}>
					{label}
				</label>
			)}
			{isLoading && <DotLoader />}
			<button
				type="button"
				id={name}
				onClick={toggleDropdown}
				className={selectToggle}
				disabled={isDisabled}
			>
				{defaultVal?.icon && (
					<img src={defaultVal?.icon} className={styles.customSelect__icon} alt="" />
				)}
				{defaultVal?.label}
			</button>
			{!isDisabled && (
				<div className={dropClass}>
					{withSearch && (
						<div className={styles.customSelect__searchWrap}>
							<input
								className={styles.customSelect__search}
								type="text"
								placeholder="Search"
								onChange={filterHandler}
								value={inputValue}
							/>
						</div>
					)}
					<ul className={styles.customSelect__list}>
						{filteredData?.map((item) => {
							return (
								<li
									key={`${item.value}-${item?.id}`}
									className={styles.customSelect__item}
								>
									<button
										type="button"
										className={getClassLi(item)}
										onClick={() => onChooseValue(item)}
									>
										{item.icon && (
											<img
												src={item.icon}
												className={styles.customSelect__icon}
												alt=""
											/>
										)}
										{item.label}
									</button>
								</li>
							)
						})}
					</ul>
				</div>
			)}
			<p className={styles.error}>{error?.[name] && error?.[name].message}</p>
		</div>
	)
}

const CustomSelectTest = (props) => {
	const { isLoading, label, labelClassname, name, className } = props
	const classSelect = classnames(styles.customSelect, className, { [styles.loading]: isLoading })

	const classLabel = classnames(styles.customSelect__label, labelClassname)
	if (isLoading) {
		return (
			<div className={classSelect}>
				{label && (
					<label className={classLabel} htmlFor={name}>
						{label}
					</label>
				)}
				<div className={styles.customSelect__loader}>
					<DotLoader />
				</div>
			</div>
		)
	}

	return <CustomSelect {...props} />
}
export default CustomSelectTest
