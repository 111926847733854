import Header from 'src/components/modules/Header'
import { useSteps } from 'src/context/steps-context'
import WithCredentials from 'src/hoc/withCredentials'

import Form from './Form'

const Step2 = () => {
	const { state } = useSteps()
	const step = state.steps ? 3 : 2
	return (
		<div className="step">
			<Header type="progress" step={step} />
			<Form />
		</div>
	)
}

export default WithCredentials(Step2)
