import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router'
import Button from 'src/components/elements/Button'
import Header from 'src/components/modules/Header'
import { Progress } from 'src/components/modules/Header/modules'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import { CREATE_PAYMENT } from 'src/graphql/mutation'
import { GET_EXTRA_LICENCES } from 'src/graphql/query'
import WithCredentials from 'src/hoc/withCredentials'
import ROUTES_NAME from 'src/router/routesNames'
import { PaymentBlock } from '../PaymentStep/PaymentStep'

const DashboardPayment = () => {
	const navigate = useNavigate()
	const { showModal, renderComponent } = useGlobalBadgeContext()
	const { data } = useQuery(GET_EXTRA_LICENCES, {
		fetchPolicy: 'network-only'
	})
	const [createPayment, { loading: paymentLoading }] = useMutation(CREATE_PAYMENT, {
		onCompleted: (res) => {
			if (res.createPayment.ok) {
				window.location.href = res.createPayment.paymentUrl
			} else {
				showModal('error', {
					color: 'error',
					text: 'Something went wrong.',
					time: 15000
				})
			}
		}
	})

	const onProceed = () => {
		createPayment()
	}
	const onGoBack = () => {
		navigate(ROUTES_NAME.DASHBOARD_ADD_LICENCE)
	}
	return (
		<>
			<Header type="profile" />
			<div className="dashboard-step">
				<div className="container container-md-center container-lg-center">
					{renderComponent()}
					<Progress step={2} className="dashboard-step__progress" position="top" />
					<div className="dashboard-step__titles">
						<h3 className="dashboard-step__title">Everything looks good?</h3>
						<p className="dashboard-step__subtitle">
							Please proceed to payment to activate your licence.
						</p>
					</div>
					<div className="dashboard-step__payment">
						<PaymentBlock licenses={data?.user?.extraLicences} />
						<div className="payment-step__disclaimer">
							<i className="icon-info-circle" />
							<p>
								You will automatically be charged monthly. You can unsubscribe at
								any time.
							</p>
						</div>
					</div>
				</div>
				<div className="step-buttons">
					<div className="step-buttons__wrapper">
						<Button color="empty" classname="step-buttons__backBtn" onClick={onGoBack}>
							<i className="icon-arrow-left" />
							Back
						</Button>
						<Button
							type="submit"
							color="primary"
							classname="step-buttons__next"
							onClick={onProceed}
							isLoading={paymentLoading}
						>
							Proceed to payment
						</Button>
					</div>
				</div>
			</div>
		</>
	)
}

export default WithCredentials(DashboardPayment)
