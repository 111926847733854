import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import 'src/styles/main.scss'
import './index.css'
import { ApolloProvider } from '@apollo/client'
import { createApolloClient } from 'src/apollo/client'
import App from './App'
import { ContextProvider } from './context/auth-context'
import { GlobalBadge } from './context/global-badge'
import { StepsContextProvider } from './context/steps-context'

const client = createApolloClient()

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<ContextProvider>
				<StepsContextProvider>
					<GlobalBadge>
						<Router>
							<App />
						</Router>
					</GlobalBadge>
				</StepsContextProvider>
			</ContextProvider>
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById('root')
)
