import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import plus from 'src/assets/images/icons/plus.svg'
import classNames from 'classnames'
import { filterArrayValues } from 'src/utils'
import Button from '../../Button'
import FieldInput from '../../FieldInput'
import styles from '../AddLicense.module.scss'
import AdditionalField from '../modules/AdditionalField'
import ChildrenCount from '../modules/ChildrenCount'

type TAddLicenceFormInner = {
	mainError: any
	error: any
	setError: Dispatch<SetStateAction<any[]>>
	dashboard?: boolean
	required?: boolean
}
const AddLicenceFormInner: React.FC<TAddLicenceFormInner> = ({
	mainError,
	error,
	setError,
	dashboard,
	required = true
}) => {
	const { control, watch } = useFormContext()
	const [childs, setChilds] = useState(0)

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'array'
	})

	const countFields = () => {
		const allFields = watch()
		const quantity = filterArrayValues(allFields).filter((item) => item).length
		const count = quantity + (allFields.counter || 0)
		setChilds(count)
	}

	useEffect(() => {
		countFields()
	}, [watch('counter')])

	const addField = () => {
		append({ id: '' })
	}

	const deleteField = (index) => {
		setError(error.filter((_, i) => i !== index))
		remove(index)
		countFields()
	}
	const blockClass = classNames(styles.license__inner, { [styles.blockDashboard]: dashboard })
	return (
		<div className={blockClass}>
			<ChildrenCount childs={childs} dashboard={dashboard} />
			<div className={styles.license__fields}>
				<FieldInput
					label="Licence code"
					placeholder="Type licence code"
					name="identifier"
					connectForm
					errors={mainError}
					onBlur={countFields}
					required={required}
				/>
				{fields.map((field, index) => {
					return (
						<AdditionalField
							key={field.id}
							deleteField={deleteField}
							name={`array.${index}.identifier`}
							onBlur={countFields}
							index={index}
							error={error[index]}
						/>
					)
				})}
			</div>
			<Button color="outlined" block onClick={addField} classname={styles.license__addButton}>
				Add more{' '}
				<img
					src={plus}
					alt="Add"
					title="Add identifier"
					className={styles.license__addIcon}
				/>
			</Button>
		</div>
	)
}

export default AddLicenceFormInner
