import classNames from 'classnames'
import useAccordion from 'src/hooks/useAccordion'

type TProps = { id: string; name: string; queries: number }

const ReportingBlockedReasons: React.FC<{ reasons: TProps[] }> = ({ reasons }) => {
	const { open, ref, toggleAccordion, height } = useAccordion('132px')
	const btnClassName = classNames('reasons__btn', { reasons__active: open })
	return (
		<div className="reasons">
			<div className="reasons__header">
				<div className="reasons__title">Blocked Reasons</div>
				<p className="reasons__subtitle">Сategories that blocked most requests.</p>
			</div>
			<ul
				ref={ref}
				style={{
					maxHeight: height,
					overflow: 'hidden',
					transition: 'max-height 0.6s ease'
				}}
			>
				{reasons?.map((reason) => (
					<li className="reasons__item" key={reason.name}>
						{reason.name} <span>{reason.queries}</span>
					</li>
				))}
			</ul>
			{reasons?.length > 4 && (
				<button className={btnClassName} onClick={toggleAccordion}>
					{open ? 'Hide' : 'Show more'} <i className="icon-chevron-down" />
				</button>
			)}
		</div>
	)
}

export default ReportingBlockedReasons
