import Header from 'src/components/modules/Header'
import HeroBlock from 'src/components/modules/HeroBlock'
import GetStarted from 'src/components/modules/GetStarted'
import ProtectionBlock from 'src/components/modules/ProtectionBlock'
import PolicyBlock from 'src/components/modules/PolicyBlock'
import Footer from 'src/components/modules/Footer'
import AuthenticatedToDashboard from 'src/hoc/AuthenticatedToDashboard'
import { useMutation } from '@apollo/client'
import { DELETE_ANONYMOUS_USER } from 'src/graphql/mutation'
import { useEffect } from 'react'
import { useDispatchSteps } from 'src/context/steps-context'

const HomePage = () => {
	const { dispatch } = useDispatchSteps()
	const [deleteAnonUser] = useMutation(DELETE_ANONYMOUS_USER)

	useEffect(() => {
		deleteAnonUser()
		dispatch({
			type: 'DELETE_STEPS'
		})
	}, [])

	return (
		<div>
			<Header />
			<HeroBlock />
			<GetStarted />
			<ProtectionBlock />
			<PolicyBlock />
			<Footer />
		</div>
	)
}

export default AuthenticatedToDashboard(HomePage)
