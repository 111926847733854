import Header from 'src/components/modules/Header'
import Button from 'src/components/elements/Button'
import {
	AddChild,
	AddChildHeader,
	AddChildAccess,
	AddChildFooter
} from 'src/components/modules/AddChild'
import { useNavigate } from 'react-router-dom'

import { GET_CHILDREN } from 'src/graphql/query'
import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import ROUTES_NAME from 'src/router/routesNames'
import Loader from 'src/components/elements/Loader'
import WithCredentials from 'src/hoc/withCredentials'
import { useDispatchSteps, useSteps } from 'src/context/steps-context'

const Step3 = () => {
	const navigate = useNavigate()
	const { dispatch } = useDispatchSteps()
	const { state } = useSteps()
	const step = state.steps ? 4 : 3
	const [getChildren, { data, loading }] = useLazyQuery(GET_CHILDREN, {
		fetchPolicy: 'network-only'
	})

	const enabled = useMemo(() => {
		return data?.children.some((child) => child.licence.status === 'PROTECTED')
	}, [data])

	useEffect(() => {
		const id = setInterval(() => {
			getChildren()
		}, 4000)

		getChildren()

		if (enabled) {
			clearInterval(id)
		}

		return () => clearInterval(id)
	}, [enabled])

	const onSubmit = () => {
		navigate(ROUTES_NAME.DASHBOARD)
		dispatch({
			type: 'DELETE_STEPS'
		})
	}
	const onCancel = () => {
		navigate(ROUTES_NAME.STEP2)
	}
	return (
		<div className="step">
			<Header type="progress" step={step} />
			<div className="step__form">
				<div className="container container-md-center container-lg-center">
					<div className="step__titles">
						<h2 className="step__title">Let’s get connected</h2>
						<p className="step__subtitle">
							Connect the application to your child's device to get protection
						</p>
					</div>
					<div className="step__desktopGrid">
						{loading && !data && <Loader width="80px" color="grey" center />}
						{data?.children.map((child) => (
							<AddChild key={child.licence.code}>
								<AddChildHeader
									childName={child.name}
									status={child.licence.status}
									loading={loading}
									type={child.licence.licenceType}
								/>
								<AddChildAccess
									childName={child.name}
									pin={child.licence.profileCode}
								/>
								<AddChildFooter status={child.licence.status} />
							</AddChild>
						))}
					</div>
				</div>

				<div className="step-buttons">
					<div className="step-buttons__wrapper">
						<Button color="empty" classname="step-buttons__backBtn" onClick={onCancel}>
							<i className="icon-arrow-left" />
							Back
						</Button>
						<Button type="submit" color="primary" onClick={onSubmit}>
							Confirm
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default WithCredentials(Step3)
