import classNames from 'classnames'
import React from 'react'
import styles from './ChildStatus.module.scss'

const ChildStatus: React.FC<{ status: string; loading?: boolean }> = ({ status, loading }) => {
	const colors = {
		IN_PROGRESS: 'yellow',
		PROTECTED: 'green',
		UNPROTECTED: 'orange',
		EXPIRED: 'red'
	}
	const statusText = {
		IN_PROGRESS: 'In progress',
		PROTECTED: 'Protected',
		UNPROTECTED: 'Unprotected',
		EXPIRED: 'Expired',
		OPEN: 'Open'
	}
	const chosenStatus = Object.keys(statusText).includes(status) ? statusText[status] : 'Inactive'
	const iconClass = classNames(styles.status__icon, styles[colors[status]])
	return (
		<div className={styles.status}>
			<div className={styles.status__title}>{loading ? 'Loading...' : chosenStatus}</div>
			<span className={iconClass} />
		</div>
	)
}

export default ChildStatus
