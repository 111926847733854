import BlockTitle from 'src/components/elements/BlockTitle'
import { useMedia } from 'react-use'
import { protectLink } from 'src/utils/links'
import styles from './ProtectionBlock.module.scss'
import protectionData from './data'

const ProtectionBlock = () => {
	const isDesktop = useMedia('(min-width: 768px)')
	return (
		<div className={styles.block}>
			<div className="container">
				<BlockTitle color="white" classname={styles.block__title}>
					What kind of things can I protect myself and my children against?
				</BlockTitle>
				<a className={styles.block__link} href={protectLink}>
					View all policies
				</a>
				<div className={styles.block__items}>
					{protectionData.map((item, index) => (
						<div className={styles.block__item} key={item.id}>
							{isDesktop ? (
								<span className={styles.block__number}>{`0${index + 1}`}</span>
							) : null}
							<div>
								<img src={item.image} alt="" className={styles.block__image} />
								<p className={styles.block__p}>{item.text}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default ProtectionBlock
