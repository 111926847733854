import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import FormCounter from 'src/components/elements/FormCounter'
import { GET_LICENSE_PRICE } from 'src/graphql/query'
import styles from '../AddLicense.module.scss'

const ExtraLicense: React.FC<{ dashboard?: boolean }> = ({ dashboard }) => {
	const { data } = useQuery(GET_LICENSE_PRICE)

	const extraBlockClass = classNames(styles.extra, {
		[styles.blockDashboard]: dashboard
	})
	const infoBlockClass = classNames(styles.extra__info, {
		[styles['info-block-dashboard']]: dashboard
	})
	const textClass = classNames(styles.license__subtext2, {
		[styles['text-dashboard']]: dashboard
	})
	const paidBlockClass = classNames(styles.license__paid, {
		[styles['paid-block-dashboard']]: dashboard
	})
	const prepaidClass = classNames(styles.license__prepaid, {
		[styles['prepaid-dashboard']]: dashboard
	})
	const quantityClass = classNames(styles.extra__quantity, {
		[styles['quantity-dashboard']]: dashboard
	})
	const priceClass = classNames(styles.extra__price, {
		[styles['price-dashboard']]: dashboard
	})
	const costClass = classNames(styles.extra__cost, {
		[styles['cost-block-dashboard']]: dashboard
	})
	const triggerClass = classNames(styles.extra__trigger, {
		[styles['trigger-block-dashboard']]: dashboard
	})
	const counterClass = classNames({
		[styles['counter-block-dashboard']]: dashboard
	})
	return (
		<div className={extraBlockClass}>
			<div className={infoBlockClass}>
				<div className={paidBlockClass}>
					<span className={textClass}>add a monthly licence</span>
					<div className={prepaidClass}>Pay-as-you-go</div>
				</div>
				<div className={costClass}>
					<sup className={styles.extra__currency}>£</sup>
					<span className={priceClass}>{data?.licencePrice}</span>
					<span className={quantityClass}>per child</span>
				</div>
			</div>
			<div className={counterClass}>
				<div className={triggerClass}>
					Please choose how many children you want to protect:
				</div>
				<FormCounter name="counter" connectForm />
			</div>
		</div>
	)
}

export default ExtraLicense
