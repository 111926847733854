import classnames from 'classnames'
import useCustomSelect from 'src/hooks/useCustomSelect'
import DotLoader from 'src/components/elements/DotLoader'
import styles from './ReportingSelect.module.scss'
import { ISearchPopupCustomSelect, Value } from './ReportingSelect.types'

const ReportingSelect: React.FC<ISearchPopupCustomSelect> = (props) => {
	const {
		defaultValue,
		list,
		onChange,
		isLoading,
		toggleClassname,
		className,
		left,
		value,
		isDisabled
	} = props

	const { isShowDropdown, toggleDropdown, chooseValue, selectedValue, ref, filteredData } =
		useCustomSelect({
			list,
			defaultValue,
			isDropdown: true,
			isFiltered: false
		})

	const onChooseValue = (val: Value, toggle = true) => {
		if (val) {
			if (onChange) {
				onChange(val)
			}
			chooseValue(val)
		}
		if (toggle) {
			toggleDropdown()
		}
	}

	const dropClass = classnames(styles.customSelect__drop, {
		[styles.open]: isShowDropdown,
		[styles.customSelect__left]: left
	})
	const defaultVal = value || selectedValue || defaultValue

	const selectToggle = classnames(styles.customSelect__toggle, toggleClassname, {
		[styles.customSelect__toggleDisabled]: isDisabled,
		[styles.customSelect__active]: isShowDropdown
		// [styles.customSelect__error]: error?.[name]
	})

	const getClassLi = (el: Value) =>
		classnames(styles.customSelect__btn, {
			[styles.customSelect__btnSelected]:
				el.value === defaultVal?.value ||
				(!selectedValue?.value && el.value === defaultValue?.value)
		})

	const classSelect = classnames(styles.customSelect, className, { [styles.loading]: isLoading })

	return (
		<div className={classSelect} ref={ref}>
			{isLoading && <DotLoader />}
			<button
				type="button"
				onClick={toggleDropdown}
				className={selectToggle}
				disabled={isDisabled}
			>
				{defaultVal?.label}
			</button>

			{!isDisabled && (
				<div className={dropClass}>
					<ul className={styles.customSelect__list}>
						{filteredData?.map((item) => {
							return (
								<li key={`${item.value}-${item?.id}`}>
									<button
										type="button"
										className={getClassLi(item)}
										onClick={() => onChooseValue(item)}
									>
										{item.label}
									</button>
								</li>
							)
						})}
					</ul>
				</div>
			)}
			{/* <p className={styles.error}>{error?.[name] && error?.[name].message}</p> */}
		</div>
	)
}

export default ReportingSelect
