import { emptyStringToNull, phoneMaskRegExp } from 'src/utils'
import * as Yup from 'yup'

export const formData = [
	{
		label: 'First name',
		type: 'text',
		id: 'firstName',
		name: 'firstName',
		placeholder: 'Type first name'
	},
	{
		label: 'Last name',
		type: 'text',
		id: 'lastName',
		name: 'lastName',
		placeholder: 'Type last name'
	},
	{
		label: 'Phone number',
		type: 'tel',
		id: 'phoneNumber',
		name: 'phoneNumber',
		placeholder: '+4407400123456'
	},
	{
		label: 'Country',
		type: 'text',
		id: 'country',
		name: 'country'
	},
	{
		label: 'City',
		type: 'text',
		id: 'city',
		name: 'city',
		placeholder: 'Type city'
	},
	{
		label: 'Address',
		type: 'text',
		id: 'address',
		name: 'address',
		placeholder: 'Type address'
	},
	{
		label: 'Postcode',
		type: 'text',
		id: 'postCode',
		name: 'postCode',
		placeholder: 'Type postcode'
	}
]

export const validationSchema = Yup.object().shape({
	phoneNumber: Yup.string()
		.matches(phoneMaskRegExp, 'Wrong format. Correct format is +440740012345')
		.required('Field is required')
		.transform(emptyStringToNull)
		.nullable(),
	lastName: Yup.string().required('Field is required'),
	firstName: Yup.string().required('Field is required'),
	address: Yup.string().required('Field is required'),
	country: Yup.object({
		label: Yup.string(),
		value: Yup.string()
	}).required('Field is required'),
	city: Yup.string().required('Field is required'),
	postCode: Yup.string().required('Field is required')
})
