import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import { UPDATE_PASSWORD } from 'src/graphql/mutation'
import ROUTES_NAME from 'src/router/routesNames'
import { serializeErrors } from 'src/utils'
import Button from '../../Button'
import FieldInput from '../../FieldInput'
import styles from '../SettingChangeBlock.module.scss'
import { passwordData } from './form'

const ChangePassword: React.FC<{ toggleModal: () => void }> = ({ toggleModal }) => {
	const { showModal } = useGlobalBadgeContext()

	const [errorsPack, setErrors] = useState<{ [key: string]: string }>(null)

	const methods = useForm()
	const { handleSubmit } = methods

	const [updatePassword] = useMutation(UPDATE_PASSWORD, {
		onCompleted: (data) => {
			if (data.updateUserPassword.errors) {
				const { errors } = data.updateUserPassword
				const t = serializeErrors(errors)
				setErrors(t)
			}
			if (data.updateUserPassword.ok) {
				setErrors(null)
				toggleModal()
				window.scrollTo(0, 0)
				showModal('success', {
					color: 'success',
					text: 'You have successfully changed your password'
				})
			}
		}
	})

	const onSubmit = (data) => {
		updatePassword({
			variables: data
		})
	}
	return (
		<div className={styles.changeModal}>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					{passwordData.map((field) => (
						<FieldInput
							key={field.name}
							{...field}
							className={styles.changeModal__input}
							errors={errorsPack?.[field.name]}
							required
						/>
					))}
					<div className={styles.changeModal__buttons}>
						<Link
							to={ROUTES_NAME.RECOVER_PASSWORD}
							className={styles.changeModal__link}
						>
							Forgot password?
						</Link>
						<Button type="submit" color="primary">
							Confirm
						</Button>
					</div>
				</form>
			</FormProvider>
		</div>
	)
}

export default ChangePassword
