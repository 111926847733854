import { useToggle } from 'react-use'
import { Device } from './ChildDevices'
import styles from '../DashboardChild.module.scss'
import Modal from '../../Modal'
import ChildExtendTime from './ChildExtendTime'
import ChildResetTime from './ChildResetTime'

type TChildChillTime = {
	chillTime?: {
		end: null | string
		start: null | string
	}
	childId: string
	refetch: any
	canReset: boolean
}

const formatDate = () => {
	const day = new Date()
		.toLocaleDateString('en-us', {
			day: '2-digit',
			month: 'short',
			weekday: 'short'
		})
		.split(' ')
		.reverse()
	const weekday = day[2].replace(',', '')
	const date = day.slice(0, 2).join(' ')
	return `${date}, ${weekday}`
}

const ChildChillTime: React.FC<TChildChillTime> = ({ chillTime, childId, refetch, canReset }) => {
	const [show, toggle] = useToggle(false)
	const [isReset, toggleReset] = useToggle(false)

	if (!chillTime && !chillTime) {
		return null
	}

	const title = `${chillTime?.start} - ${chillTime?.end}`

	const formattedDate = formatDate()
	return (
		<>
			<div className={styles.devices}>
				<div className={styles.devices__titles}>
					<div className={styles.devices__label}>Chill time</div>
					<div>
						{canReset && (
							<button className={styles.devices__reset} onClick={toggleReset}>
								Reset
							</button>
						)}
						<button className={styles.devices__btn} onClick={toggle}>
							Change
						</button>
					</div>
				</div>
				<div className={styles.devices__wrapper}>
					<span className={styles.extend__disclaimer}>
						Period for each day of the week during which access to blocked websites and
						apps is allowed. Change it if necessary
					</span>
					<Device title={title} subtitle={`Chill today • ${formattedDate}`} />
				</div>
			</div>
			{show && (
				<Modal show={show} toggleModal={toggle} title="Change chill time">
					<ChildExtendTime
						toggleModal={toggle}
						title={title}
						subtitle={`Chill today • ${formattedDate}`}
						childId={childId}
						refetch={refetch}
					/>
				</Modal>
			)}
			{isReset && (
				<Modal show={isReset} toggleModal={toggleReset} title="Reset time">
					<ChildResetTime toggleModal={toggleReset} childId={childId} refetch={refetch} />
				</Modal>
			)}
		</>
	)
}

export default ChildChillTime
