import { useToggle } from 'react-use'
import Modal from '../Modal'
import { ChangeEmail, ChangePassword } from './modules'
import styles from './SettingChangeBlock.module.scss'

const SettingChangeBlock: React.FC<{
	label: string
	text: string
	email?: string
	onClick?: () => void
}> = ({ label, text, email }) => {
	const [show, toggle] = useToggle(false)
	const elements = {
		element: {
			email: <ChangeEmail toggleModal={toggle} />,
			password: <ChangePassword toggleModal={toggle} />
		},
		fullText: {
			email: 'Change current email address you use to login and receive notifications.',
			password: 'Change current password used to login and requested for some actions.'
		}
	}
	return (
		<>
			<div className={styles.block}>
				<div className={styles.block__label}>{label}</div>
				<p className={styles.block__text}>{elements.fullText[text]}</p>
				{email && <div className={styles.block__email}>{email}</div>}
				<button className={styles.block__btn} onClick={toggle}>
					<i className="icon-edit" /> Change {text}
				</button>
			</div>
			{show && (
				<Modal show={show} toggleModal={toggle} title={`Change ${text}`}>
					{elements.element[text]}
				</Modal>
			)}
		</>
	)
}

export default SettingChangeBlock
