import ChildStatus from 'src/components/elements/ChildStatus'
import styles from '../AddChild.module.scss'

const licenceType = {
	PRE_PAID: 'Pre-paid',
	EXTRA: 'Extra',
	ACTIVE: 'Active'
}
const AddChildHeader: React.FC<{
	childName?: string
	index?: number
	type: string
	status: string
	loading?: boolean
}> = ({ childName, index, status, loading, type }) => {
	const name = childName || `Child • ${index + 1}`
	return (
		<div className={styles.child__status}>
			<div>
				<div className={styles.child__type}>{licenceType[type]} licence</div>
				<div className={styles.child__title}>{name}</div>
			</div>
			<ChildStatus status={status} loading={loading} />
		</div>
	)
}

export default AddChildHeader
