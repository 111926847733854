import { useMutation } from '@apollo/client'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import { RESET_EXTEND_TIME } from 'src/graphql/mutation'
import Button from '../../Button'
import styles from '../DashboardChild.module.scss'

export type TTimeValue = {
	hours: number
	minutes: number
}

export type TChildResetTime = {
	toggleModal: () => void
	childId: string
	refetch: any
}

const ChildResetTime: React.FC<TChildResetTime> = ({ toggleModal, childId, refetch }) => {
	const { showModal } = useGlobalBadgeContext()
	const [resetTime] = useMutation(RESET_EXTEND_TIME, {
		onCompleted: (data) => {
			if (data.resetRecreationTime.ok) {
				refetch()
				showModal('info', {
					color: 'info',
					text: 'Extended chill time was reset',
					time: 10000
				})
			}
			if (data.resetRecreationTime.errors.length) {
				showModal('error', {
					color: 'error',
					text: data.resetRecreationTime.errors[0].errors[0],
					time: 10000
				})
			}
			toggleModal()
		}
	})
	const onSubmit = () => {
		resetTime({
			variables: {
				childId
			}
		})
	}
	return (
		<div>
			<div className={styles.extend}>
				<span className={styles.reset__disclaimer}>
					Are you sure you want to reset changed chill time to standard time?
				</span>
			</div>
			<div className={styles.buttons}>
				<Button color="empty" onClick={toggleModal}>
					Cancel
				</Button>
				<Button type="submit" color="danger" onClick={onSubmit}>
					Reset
				</Button>
			</div>
		</div>
	)
}

export default ChildResetTime
