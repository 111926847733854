import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useToggle } from 'react-use'
import { UPDATE_CHILD } from 'src/graphql/mutation'
import Button from '../../Button'
import FieldInput from '../../FieldInput'
import styles from '../DashboardChild.module.scss'

const ChildNameForm: React.FC<{ childName: string; childId: string; refetch: any }> = ({
	childName,
	childId,
	refetch
}) => {
	const [name, setName] = useState(childName)
	const [updateChild] = useMutation(UPDATE_CHILD, {
		onCompleted: (data) => {
			if (data.updateChild.ok) {
				refetch()
			}
		}
	})
	const [show, toggle] = useToggle(false)
	const onSubmit = (e) => {
		e.preventDefault()
		updateChild({
			variables: {
				childId,
				name
			}
		})
	}

	useEffect(() => {
		if (childName !== name) {
			toggle(true)
		}
		if (name === '' || name === childName) {
			toggle(false)
		}
	}, [name, childName])
	return (
		<div className={styles.field}>
			<form className={styles.field__form} onSubmit={onSubmit}>
				<FieldInput
					name="child"
					label="Name"
					placeholder="Type child’s name"
					value={name}
					onChange={(e) => setName(e.target.value)}
					className={styles.field__input}
					required
				/>
				{show && (
					<Button color="outlined" classname={styles.field__btn} type="submit">
						Save
					</Button>
				)}
			</form>
			<span className={styles.field__disclaimer}>
				Child's name that appears on their profile. Change it if necessary.
			</span>
		</div>
	)
}

export default ChildNameForm
