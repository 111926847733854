import { useLazyQuery, useQuery } from '@apollo/client'
import { FC, useEffect, useState } from 'react'
import Loader from 'src/components/elements/Loader'
import ReportingSelect from 'src/components/elements/ReportingSelect'
import { GET_ANALYTICS, GET_TIME_INTERVALS } from 'src/graphql/query'
import ReportingBlockedReasons from './ReportingBlockedReasons'
import ReportingGraph, { getSpecificStatus } from './ReportingGraph'

type SelectValue = {
	id?: string
	label: string
	value: string
}
type DashboardReportingProps = {
	childrenNames: [SelectValue]
	loadingChildren: boolean
}
const DashboardReporting: FC<DashboardReportingProps> = ({ childrenNames, loadingChildren }) => {
	const [getAnalytics, { data, loading }] = useLazyQuery(GET_ANALYTICS, {
		fetchPolicy: 'cache-first'
	})
	const { data: intervals, loading: loadingIntervals } = useQuery(GET_TIME_INTERVALS)
	const defaultInterval = { label: 'Last 30 days', value: '-30d' }

	const defaultChild = {
		label: 'All children',
		value: 'default',
		id: 'default'
	}
	const [child, setChild] = useState<SelectValue>(defaultChild)
	const [interval, setInterval] = useState<SelectValue>(defaultInterval)
	const onChangeChild = (val: SelectValue) => {
		setChild(val)
	}
	const onChangeInterval = (val: SelectValue) => {
		setInterval(val)
	}

	useEffect(() => {
		if (interval?.value) {
			const variables = {
				timeInterval: interval.value,
				childId: child.value === 'default' ? '' : child.value
			}
			getAnalytics({ variables })
		}
	}, [child.value, interval?.value])

	const allQueries = getSpecificStatus(data?.analytics.queries, 'total')
	const blocked = getSpecificStatus(data?.analytics.queries, 'blocked')
	const percent = getSpecificStatus(data?.analytics.queries, 'blocked_percent')

	return (
		<div className="report">
			<div className="report__filters">
				<ReportingSelect
					defaultValue={defaultChild}
					onChange={onChangeChild}
					value={child}
					list={[defaultChild, ...childrenNames]}
					isLoading={loadingChildren}
					isDisabled={loading}
				/>
				<ReportingSelect
					defaultValue={defaultInterval}
					onChange={onChangeInterval}
					list={intervals?.timeIntervals}
					value={interval}
					isLoading={loadingIntervals}
					isDisabled={loading}
					left
				/>
			</div>
			{loading ? (
				<Loader width="80px" color="grey" center />
			) : (
				<>
					<div className="report__metrics">
						<div className="report__item m1">
							<p className="report__quantity all-queries">{allQueries}</p>
							<span className="report__span">All queries</span>
						</div>
						<div className="report__item m2">
							<p className="report__quantity blocked">{blocked}</p>
							<span className="report__span"> Blocked</span>
						</div>
						<div className="report__item m3">
							<p className="report__percent">
								<span>{percent}%</span> of blocked queries
							</p>
						</div>
					</div>
					<div className="report__graphics">
						<ReportingGraph
							data={data?.analytics?.querySeries}
							interval={interval?.value}
						/>
						<ReportingBlockedReasons reasons={data?.analytics?.blockedReasons} />
					</div>
				</>
			)}
		</div>
	)
}

export default DashboardReporting
