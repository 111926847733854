import { Link, useNavigate } from 'react-router-dom'
import Button from 'src/components/elements/Button'
import FieldInput from 'src/components/elements/FieldInput'
import { useForm, FormProvider } from 'react-hook-form'
import { LOGIN_USER } from 'src/graphql/mutation'
import { useMutation } from '@apollo/client'
import { useDispatchUser } from 'src/context/auth-context'
import { useState } from 'react'
import ROUTES_NAME from 'src/router/routesNames'
import styles from './Login.module.scss'

const serializeError = (errArr) =>
	errArr.reduce((acc, curr) => {
		return {
			...acc,
			[curr.fieldName]: {
				message: curr.errors[0]
			}
		}
	}, {})

const Login = () => {
	const methods = useForm()
	const navigate = useNavigate()
	const [errors, setError] = useState(null)
	const { dispatch } = useDispatchUser()
	const { handleSubmit } = methods
	const [loginUser, { loading }] = useMutation(LOGIN_USER, {
		onCompleted: (data) => {
			if (data.loginUser.ok) {
				dispatch({
					type: 'LOGIN',
					payload: {
						user: data.loginUser.user
					}
				})
				setError(null)
				if (data.loginUser.user.licences.length > 0) {
					navigate(ROUTES_NAME.STEP2)
				} else {
					navigate(ROUTES_NAME.DASHBOARD)
				}
			}
			if (!data.loginUser.ok) {
				setError(serializeError(data.loginUser.errors))
			}
		}
	})

	const onSubmit = handleSubmit((data) => {
		loginUser({ variables: data })
	})
	return (
		<div className={styles.login}>
			<div className="container container-md-center">
				<Link to="/">
					<img src="/images/logotype-big.png" alt="" className={styles.login__logo} />
				</Link>
				<FormProvider {...methods}>
					<form className={styles.login__form} onSubmit={onSubmit}>
						<h2 className={styles.login__title}>Login</h2>
						<FieldInput
							name="email"
							placeholder="Type email"
							label="Email"
							className={styles.login__input}
							connectForm
							errors={errors?.email}
							required
						/>
						<FieldInput
							name="password"
							placeholder="Type password"
							label="Password"
							isPassword
							connectForm
							className={styles.login__password}
							errors={errors?.password}
							required
						/>
						<Link to={ROUTES_NAME.RECOVER_PASSWORD} className={styles.login__forgot}>
							Forgot password?
						</Link>
						<Button type="submit" color="primary" block isLoading={loading}>
							Login
						</Button>
					</form>
				</FormProvider>
				<span className={styles.login__account}>
					Don’t have an account? <Link to={ROUTES_NAME.HOME}>Get started</Link>
				</span>
				{/* <div className={styles.separator}>or</div>
				<SocialLoginButton icon="google">Continue with Google</SocialLoginButton>
				<SocialLoginButton icon="apple">Continue with Apple</SocialLoginButton>
				<SocialLoginButton icon="linkedin">Continue with Linkedin</SocialLoginButton> */}
				<span className={styles.login__copyright}>
					© 2022 School Mobile. All rights reserved.
				</span>
			</div>
		</div>
	)
}

export default Login
