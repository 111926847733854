import { Link } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { UPDATE_USER_EMAIL } from 'src/graphql/mutation'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import { censorEmail, serializeErrors } from 'src/utils'
import { useState } from 'react'
import ROUTES_NAME from 'src/router/routesNames'
import Button from '../../Button'
import FieldInput from '../../FieldInput'
import styles from '../SettingChangeBlock.module.scss'
import { emailData } from './form'

const ChangeEmail: React.FC<{ toggleModal: () => void }> = ({ toggleModal }) => {
	const { showModal } = useGlobalBadgeContext()
	const [errorsPack, setErrors] = useState<{ [key: string]: string }>(null)

	const methods = useForm()
	const { handleSubmit } = methods

	const [updateEmail] = useMutation(UPDATE_USER_EMAIL, {
		onCompleted: (data) => {
			if (data.updateUserEmail.errors) {
				const { errors } = data.updateUserEmail
				const t = serializeErrors(errors)
				setErrors(t)
			}
			if (data.updateUserEmail.ok) {
				toggleModal()
				setErrors(null)
				window.scrollTo(0, 0)
				showModal('info', {
					color: 'info',
					text: `We sent a verification email to ${censorEmail(
						data.updateUserEmail.user.email
					)}. Please check it to verify your new email.`
				})
			}
		}
	})
	const onSubmit = (data) => {
		updateEmail({ variables: data })
	}
	return (
		<div className={styles.changeModal}>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<p className={styles.changeModal__disclaimer}>
						We will send a verification message to your <span>new email</span> address.
						Please click the "Confirm Email" button inside to complete the change.
					</p>
					{emailData.map((field) => (
						<FieldInput
							key={field.name}
							{...field}
							className={styles.changeModal__input}
							errors={errorsPack?.[field.name]}
							required
						/>
					))}
					<div className={styles.changeModal__buttons}>
						<Link
							to={ROUTES_NAME.RECOVER_PASSWORD}
							className={styles.changeModal__link}
						>
							Forgot password?
						</Link>
						<Button type="submit" color="primary">
							Submit
						</Button>
					</div>
				</form>
			</FormProvider>
		</div>
	)
}

export default ChangeEmail
