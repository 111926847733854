import classNames from 'classnames'
import { FAQLink, helpLinks } from 'src/utils/links'
import Button from '../Button'
import styles from './MobileMenu.module.scss'

const Menu: React.FC<{ toggleMenu: () => void }> = ({ toggleMenu }) => {
	const buttonClass = classNames(styles.menu__close, 'icon-close')

	return (
		<div className={styles.menu}>
			<div className={styles.menu__header}>
				<img src="/images/logotype-big.png" alt="" className={styles.menu__logo} />
				<button
					type="button"
					className={buttonClass}
					aria-label="Close window"
					onClick={toggleMenu}
				/>
			</div>
			<div className={styles.menu__main}>
				<div className={styles.menu__part}>
					<strong className={styles.menu__title}>Help</strong>
					<ul>
						{helpLinks.map((link) => (
							<li key={link.title} className={styles.menu__link}>
								<a href={link.url}>{link.title}</a>
							</li>
						))}
					</ul>
				</div>
				<div className={styles.menu__part}>
					<a href={FAQLink} className={styles.menu__title}>
						FAQs
					</a>
				</div>
			</div>

			<Button color="outlined" classname={styles.menu__login}>
				Login
			</Button>
		</div>
	)
}

export default Menu
