import classNames from 'classnames'
import React from 'react'
import styles from './BlockTitle.module.scss'

type TitleProps = {
	children: React.ReactNode
	color: 'white' | 'black'
	classname?: string
}

const BlockTitle: React.FC<TitleProps> = ({ children, color, classname }) => {
	const getClass = classNames(styles.title, styles[color], classname)
	return <h2 className={getClass}>{children}</h2>
}

export default BlockTitle
