import * as Yup from 'yup'
import { emptyStringToNull, isNullOrUndefined, phoneMaskRegExp } from 'src/utils'

export const orderingFormData = [
	{
		label: 'First name',
		type: 'text',
		id: 'firstName',
		name: 'firstName',
		placeholder: 'Type first name'
	},
	{
		label: 'Last name',
		type: 'text',
		id: 'lastName',
		name: 'lastName',
		placeholder: 'Type last name'
	},

	{
		label: 'Email',
		type: 'email',
		id: 'email',
		name: 'email',
		placeholder: 'Type email',
		inputNativeProps: {
			autoComplete: 'email'
		}
	},
	{
		label: 'Phone number',
		type: 'tel',
		id: 'phoneNumber',
		name: 'phoneNumber',
		placeholder: 'Type phone number'
	},
	{
		label: 'Country',
		type: 'text',
		id: 'country',
		name: 'country'
	},
	{
		label: 'City',
		type: 'text',
		id: 'city',
		name: 'city',
		placeholder: 'Type city'
	},
	{
		label: 'Address',
		type: 'text',
		id: 'address',
		name: 'address',
		placeholder: 'Type address'
	},
	{
		label: 'Postcode',
		type: 'text',
		id: 'postCode',
		name: 'postCode',
		placeholder: 'Type postcode'
	},
	{
		label: 'Password',
		type: 'password',
		id: 'password1',
		name: 'password1',
		isPassword: true,
		placeholder: 'Create password',
		inputNativeProps: {
			autoComplete: 'new-password'
		}
	},
	{
		label: 'Confirm password',
		type: 'password',
		id: 'password2',
		isPassword: true,
		name: 'password2',
		placeholder: 'Type password again',
		inputNativeProps: {
			autoComplete: 'new-password'
		}
	}
]

export const validationSchema = Yup.object().shape({
	phoneNumber: Yup.string()
		.matches(phoneMaskRegExp, 'Wrong format. Correct format is +440740012345')
		.required('Field is required')
		.transform(emptyStringToNull)
		.nullable(),
	email: Yup.string().email('Email must be a valid').required('Field is required'),
	lastName: Yup.string().required('Field is required'),
	firstName: Yup.string().required('Field is required'),
	address: Yup.string().required('Field is required'),
	country: Yup.object({
		label: Yup.string(),
		value: Yup.string()
	}).required('Field is required'),
	city: Yup.string().required('Field is required'),
	postCode: Yup.string()
		.required('Field is required')
		.max(12, 'Max length 12')
		.test(
			'Invalid post code',
			'Invalid post code',
			(value) => !!value.match(/^[A-Za-z0-9][A-Za-z0-9- ]{1,10}[A-Za-z0-9]$/g)
		),
	password1: Yup.string()
		.required('Field is required')
		.min(8, 'Minimum password length 8')
		.test(
			'minUppercase',
			'Password must contain at least one capital letter and digit',
			(value) =>
				isNullOrUndefined(value) ||
				(value.match(/(?=.*[0-9])(?=.*[A-Z])/g) || []).length >= 1
		),
	password2: Yup.string()
		.required('Field is required')
		.oneOf([Yup.ref('password1'), null], 'Passwords must match'),
	termsOfUse: Yup.bool().oneOf([true], 'Field is required')
})
