import React, { FC, useEffect } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { useScrollbarWidth } from 'react-use'
import styles from './Modal.module.scss'

export const modalRoot = typeof window !== 'undefined' ? document.getElementById('root') : null
const Modal: FC<{
	show: boolean
	toggleModal: () => void
	additionalClass?: string
	title: string
	children: React.ReactNode
}> = (props) => {
	const { show = false, toggleModal, additionalClass, children, title } = props

	const sbw = useScrollbarWidth()

	const onOverlay = (e) => {
		if (e.currentTarget === e.target) {
			toggleModal()
		}
	}

	useEffect(() => {
		const $body = document.querySelector('body')
		$body.classList.add('modal-open')
		$body.style.paddingRight = `${sbw}px`

		return () => {
			$body.classList.remove('modal-open')
			$body.style.paddingRight = null
		}
	}, [show])

	const buttonClass = classNames(styles.close, 'icon-close')
	const contentClass = classNames(styles.modal__content, additionalClass)

	return createPortal(
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div className={styles.modal__overlay} onMouseUp={onOverlay}>
			<div className={styles.modal__dialog}>
				<div className={contentClass}>
					<div className={styles.modal__header}>
						<div className={styles.modal__title}>{title}</div>
						<button
							type="button"
							className={buttonClass}
							onClick={toggleModal}
							aria-label="Close window"
						/>
					</div>
					{children}
				</div>
			</div>
		</div>,
		modalRoot
	)
}

export default Modal
