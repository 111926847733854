import { Link } from 'react-router-dom'
import links, { termsAndPolicyLinks } from 'src/utils/links'
import styles from '../Footer.module.scss'

const FooterLinks = ({ title, hrefs, additionalLink }) => {
	const show = false
	return (
		<div className={styles.footer__part}>
			{show && (
				<>
					<strong className={styles.footer__title}>{title}</strong>
					<ul>
						{hrefs.map((link) => (
							<li key={link.title} className={styles.footer__link}>
								<Link to={link.url}>{link.title}</Link>
							</li>
						))}
						<li className={styles.footer__link}>
							<Link to={additionalLink.link}>{additionalLink.title}</Link>
						</li>
					</ul>
				</>
			)}
			<ul>
				<li className={styles.footer__link}>
					<a href={additionalLink.link}>{additionalLink.title}</a>
				</li>
			</ul>
		</div>
	)
}
const Footer = () => {
	return (
		<div className={styles.footer}>
			<div className="container">
				<div className={styles.footer__links}>
					<FooterLinks
						title="About us"
						hrefs={links.aboutUs}
						additionalLink={termsAndPolicyLinks.policy}
					/>
					<FooterLinks
						title="Learn"
						hrefs={links.learn}
						additionalLink={termsAndPolicyLinks.terms}
					/>
				</div>
				<div>
					<img src="/images/logotype.png" alt="" className={styles.footer__logo} />
					<p className={styles.footer__copyright}>
						© 2022 School Mobile. All rights reserved.
					</p>
				</div>
			</div>
		</div>
	)
}

export default Footer
