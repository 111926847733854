import CustomSelect from 'src/components/elements/CustomSelect'
import FieldInput from 'src/components/elements/FieldInput'
import { Controller } from 'react-hook-form'
import styles from '../AddChild.module.scss'

const AddChildForm: React.FC<{
	control: any
	list: any
	selectName: string
	inputName: string
	loading: boolean
}> = ({ control, selectName, inputName, loading, list }) => {
	return (
		<div className={styles.child__form}>
			<FieldInput
				name={inputName}
				connectForm
				label="Name"
				placeholder="Type child’s name"
				required
			/>
			<Controller
				control={control}
				name={selectName}
				render={({ field }) => (
					<CustomSelect
						name={selectName}
						field={field}
						defaultValue={{ label: 'Select policy', value: 'default' }}
						list={list}
						label="Policy"
						isLoading={loading}
						value={field.value}
					/>
				)}
			/>
		</div>
	)
}

export default AddChildForm
