import { useMedia } from 'react-use'
import DesktopFooter from './modules/DesktopFooter'
import MobileFooter from './modules/MobileFooter'

const Footer = () => {
	const isDesktop = useMedia('(min-width: 768px)')
	return isDesktop ? <DesktopFooter /> : <MobileFooter />
}

export default Footer
