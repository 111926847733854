import classNames from 'classnames'
import styles from '../../AddLicense.module.scss'

const ChildrenCount: React.FC<{ childs: number; dashboard?: boolean }> = ({
	childs,
	dashboard
}) => {
	const prepaidClass = classNames(styles.license__prepaid, {
		[styles['prepaid-dashboard']]: dashboard
	})
	const numberClass = classNames(styles.license__number, {
		[styles['number-dashboard']]: dashboard
	})
	const textClass = classNames(styles.license__subtext, {
		[styles['text-dashboard']]: dashboard
	})
	const paidBlockClass = classNames(styles.license__paid, {
		[styles['paid-block-dashboard']]: dashboard
	})
	const infoBlockClass = classNames(styles.license__info, {
		[styles['info-block-dashboard']]: dashboard
	})
	const numberBlockClass = classNames(styles.license__childrenNumber, {
		[styles['number-block-dashboard']]: dashboard
	})
	return (
		<div className={infoBlockClass}>
			<div className={paidBlockClass}>
				<span className={textClass}>annual licence</span>
				<div className={prepaidClass}>Pre-paid</div>
			</div>
			<div className={numberBlockClass}>
				<span className={numberClass}>{childs}</span>
				<span className={textClass}>children added</span>
			</div>
		</div>
	)
}

export default ChildrenCount
