import { helpLinks, FAQLink } from 'src/utils/links'
// import { Link } from 'react-router-dom'
import { useToggle } from 'react-use'
import classNames from 'classnames'
import { useRef } from 'react'
import useOnClickOutside from 'src/hooks/useOnClickOutside'
import styles from './DesktopMenu.module.scss'

const Dropdown = ({ title, list }) => {
	const [isOpen, toggle] = useToggle(false)
	const getClass = classNames(styles.menu__list, { [styles.active]: isOpen })
	const getTitleClass = classNames(styles.menu__title, { [styles.menu__titleActive]: isOpen })
	const ref = useRef(null)
	useOnClickOutside(ref, () => toggle(false))
	return (
		<div className={styles.menu__part} ref={ref}>
			<button className={getTitleClass} onClick={toggle}>
				{title} <i className={`icon-chevron-${isOpen ? 'up' : 'down'}`} />
			</button>
			<ul className={getClass}>
				{list.map((link) => (
					<li key={link.title} className={styles.menu__link}>
						<a href={link.url}>{link.title}</a>
					</li>
				))}
			</ul>
		</div>
	)
}

const DesktopMenu = () => {
	return (
		<div className={styles.menu}>
			<Dropdown title="Help" list={helpLinks} />
			<div className={styles.menu__part}>
				<a href={FAQLink} className={styles.menu__title}>
					FAQs
				</a>
			</div>
		</div>
	)
}

export default DesktopMenu
