import { useController, useFormContext, useWatch } from 'react-hook-form'
import React from 'react'

const SelectWrapper = ({
	children,
	name,
	list,
	watch,
	isLoading
}: {
	children: React.ReactElement
	name: string
	list: any[]
	watch?: string
	isLoading: boolean
}) => {
	const methods = useFormContext()

	const { field } = useController({
		name,
		control: methods.control
	})

	const start = useWatch({
		control: methods.control,
		name: watch
	})

	const index = !isLoading && list.findIndex((item) => item.value === start?.value) + 1

	if (index > 0) {
		if (React.Children.only(children)) {
			return React.cloneElement(children, {
				...children.props,
				control: methods.control,
				isLoading,
				setValue: methods.setValue,
				value: field.value,
				list: list.slice(index > 0 ? index : null),
				field
			})
		}
	}

	if (React.Children.only(children)) {
		return React.cloneElement(children, {
			...children.props,
			control: methods.control,
			setValue: methods.setValue,
			value: field.value,
			isLoading,
			list,
			field
		})
	}
	return null
}

export default SelectWrapper
