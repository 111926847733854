import { FormProvider, useForm } from 'react-hook-form'
import Button from 'src/components/elements/Button'
import FieldInput from 'src/components/elements/FieldInput'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { RESET_USER_PASSWORD } from 'src/graphql/mutation'
import { censorEmail, serializeErrors } from 'src/utils'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import useMultiErrors from 'src/hooks/useMultiErrors'

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Email must be a valid').required('Field is required')
})

const RecoverPassword = () => {
	const methods = useForm({ resolver: yupResolver(validationSchema) })
	const {
		handleSubmit,
		formState: { errors },
		watch
	} = methods
	const { errorsPack, setErrors } = useMultiErrors(errors)
	const [email, setEmail] = useState<string>(null)
	const navigate = useNavigate()
	const [resetPassword] = useMutation(RESET_USER_PASSWORD, {
		onCompleted: (data) => {
			const txt = watch('email')
			if (!data.resetUserPassword.ok) {
				setErrors(serializeErrors(data.resetUserPassword.errors))
				setEmail(null)
			} else {
				setEmail(txt)
			}
		}
	})

	const onSubmit = (data) => {
		resetPassword({ variables: { email: data.email } })
	}

	const onGoBack = () => {
		navigate(-1)
	}

	return (
		<div className="reset">
			<div className="container container-md-center">
				<img src="/images/logotype-big.png" alt="" className="reset__logo" />
				<h2 className="reset__title">Recover password</h2>
				{!email && (
					<div>
						<p className="reset__subtitle">
							Type your email address and we will send you a link to reset your
							password
						</p>
						<FormProvider {...methods}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<FieldInput
									name="email"
									type="email"
									label="Email"
									placeholder="Type email"
									required
									connectForm
									className="reset__input"
									errors={errorsPack?.email}
								/>
								<Button type="submit" color="primary" block>
									Reset Password
								</Button>
							</form>
						</FormProvider>
						<Button color="empty" classname="reset__back" onClick={onGoBack}>
							<i className="icon-arrow-left" />
							Back
						</Button>
					</div>
				)}
				{email && (
					<div className="send">
						<div className="send__title">Please check your email</div>
						<p className="send__subtitle">
							We sent an email to {censorEmail(email)} with instructions to restore
							access to your profile.
						</p>
					</div>
				)}
				<div className="reset__copyright">© 2022 School Mobile. All rights reserved.</div>
			</div>
		</div>
	)
}

export default RecoverPassword
